import ArrowRightIcon from "assets/icons/ArrowRightIcon";
import {
  SET_PAYMENT_TYPE,
  SET_TOGGLE_PAYMENT_MODAL,
} from "features/bog/reducer/actions";
import bog from "../../assets/images/bogIcon.svg";
import liberty from "../../assets/images/libertyIcon.svg";
import tbc from "../../assets/images/tbcIcon.svg";
import credo from "../../assets/images/credoIcon.svg";

import React from "react";
import { Spinner } from "components/Spinner";

export default function OBPaymentMethod({
  name,
  id,
  // Photo,
  // paymentType,
  dispatch,
  shouldHaveArrow = false,
  fn = false,
  setObProviderId,
  isLoading,
  isLoadingOB,
}) {
  let src;
  let bankName;
  let paymentType;
  if (name == "თიბისი") {
    paymentType = "ob_tbc";
    bankName = "TBC Bank";
    src = tbc;
  } else if (name == "საქართველო") {
    paymentType = "ob_bog";
    bankName = "Bank of Georgia";
    src = bog;
  } else if (name == "კრედო") {
    paymentType = "ob_credo";
    bankName = "Credo Bank";
    src = credo;
  } else if (name == "ლიბერთი") {
    paymentType = "ob_liberty";
    bankName = "Liberty Bank";
    src = liberty;
  }
  return (
    <button
      disabled={isLoadingOB}
      className={
        "flex w-full p-[10px] justify-between  rounded-2xl [&_svg]:min-w-[40px] [&_button]:inline-flex [&_button]:items-center w- gap-[10px] items-center cursor-pointer border-[1px] border-[#6C63FF]/[20%] hover:border-[#6C63FF]/[50%] "
      }
      style={{ backgroundColor: "rgba(116, 116, 128, 0.08)" }}
      onClick={() => {
        if (fn) {
          fn();
        }
        if (setObProviderId) {
          setObProviderId(id);
        }
        if (dispatch) {
          dispatch({ type: SET_PAYMENT_TYPE, payload: paymentType });
          dispatch({ type: SET_TOGGLE_PAYMENT_MODAL });
        }
      }}
    >
      <div className="flex items-center gap-[10px] ">
        <img src={src} alt={bankName} className="w-15 h-10 rounded-[8px]" />
        <p className="text-[16px] font-[400] leading-[19.09px] font-sans">
          {bankName}
        </p>
      </div>
      {shouldHaveArrow && !isLoading ? <ArrowRightIcon /> : null}
      {isLoading && <Spinner />}
    </button>
  );
}
