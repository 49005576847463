export default function ExclamIcon({ status }) {
  return (
    <svg
      width="92"
      height="93"
      viewBox="0 0 92 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M46 92.6113C71.4051 92.6113 92 72.0164 92 46.6113C92 21.2062 71.4051 0.611328 46 0.611328C20.5949 0.611328 0 21.2062 0 46.6113C0 72.0164 20.5949 92.6113 46 92.6113Z"
        fill={`${status == 429 ? "#BADD8C" : "red"}`}
      />
      <path
        d="M48.9897 53.0071C48.4124 53.6761 47.5773 54.0105 46.4845 54.0105C45.3918 54.0105 44.5567 53.6869 43.9794 53.0395C43.4021 52.3706 43.0928 51.432 43.0515 50.2236L42.6495 26.3045C42.6495 26.2182 42.6392 26.1103 42.6186 25.9808C42.6186 25.8298 42.6186 25.7327 42.6186 25.6895C42.6186 24.438 42.9691 23.4454 43.6701 22.7118C44.3918 21.9782 45.3402 21.6113 46.5155 21.6113C47.6907 21.6113 48.6289 21.9782 49.3299 22.7118C50.0309 23.4454 50.3814 24.438 50.3814 25.6895C50.3814 25.7327 50.3711 25.8298 50.3505 25.9808C50.3505 26.1103 50.3505 26.2182 50.3505 26.3045L49.9485 50.2236C49.9072 51.4104 49.5876 52.3382 48.9897 53.0071ZM49.6701 68.2843C48.8041 69.169 47.7423 69.6113 46.4845 69.6113C45.2268 69.6113 44.1649 69.169 43.299 68.2843C42.433 67.378 42 66.2883 42 65.0152C42 63.7421 42.433 62.6632 43.299 61.7786C44.1649 60.8939 45.2268 60.4515 46.4845 60.4515C47.7423 60.4515 48.8041 60.8939 49.6701 61.7786C50.5567 62.6632 51 63.7421 51 65.0152C51 66.2883 50.5567 67.378 49.6701 68.2843Z"
        fill={`${status == 429 ? "#8CC63F" : "red"}`}
      />
    </svg>
  );
}
