import { Routes, Route } from "react-router-dom";
import React, { lazy, useState } from "react";

import { useEffect } from "react";
import EmoneyTipPage from "features/emoneyTip";
import SuccessPage from "features/success";
import DowntimePage from "features/downtime";
import PrivacyPage from "features/privacy/PrivacyPage";
// import TermsPage from "features/terms/TermsPage-old";
import TermsPageNew from "features/terms/TermsPage";
import { useTranslation } from "react-i18next";
import { useQueryString } from "hooks";
import PaymentFailedPage from "features/fail/PaymentFailedPage";
import BogPayment from "features/bog/BogPayment";
import PaymentRedirect from "features/paymentRedirect/PaymentRedirect";
import ScrollTop from "components/ScrollTop";
import "./index.css";
import { ChatProvider } from "features/chat";
import BogPayment1 from "features/bog/BogPayment1";
import NewLandingPage, { Container } from "features/newLanding/LandingPage";
import BogPaymentWeb from "features/bog/BogPaymentWeb";
import Pending from "features/Pending/Pending";
import CustomTip from "features/success/components/CustomTip";
import { isMobile } from "react-device-detect";
import SupplementaryContractualProvisionsPage from "features/SupplementaryContractualProvisions/SupplementaryContractualProvisionsPage";
import Layout from "features/newLanding/Layout";
import RequestAndSend from "features/newLanding/RequestAndSendSection/RequestAndSend";
import Modal from "features/newLanding/Modal/Modal";
import SuccessModal from "features/newLanding/Modal/SuccessModal";
import LibertyPage from "features/newLanding/components/LibertyPage";
import UsageCases from "features/newLanding/WhyKeepzSection/UsageCases";
import ForBusiness from "features/newLanding/MerchantsSection/ForBusiness";
import QrTerminal from "features/newLanding/QrTerminal/QrTerminalPage";
import RedirectPage from "components/redirect/RedirectPage";

const TestUfc = lazy(() => import("features/bog/TestUfc"));
const TestTbc = lazy(() => import("features/bog/TestTbc"));

const App = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const { parseQuery: parsedQuery } = useQueryString();
  const { lng = "", tiny } = parsedQuery;
  if (tiny) {
    window.location.href = `https://tinyurl.com/${tiny}`;
  }
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lng) {
      const newLng = lng === "ge" ? "ge" : "en";
      i18n.changeLanguage(newLng);
    }
  }, [lng, i18n]);

  function openContactModel() {
    setShowContactModal(true);
  }

  const reload = () => {
    window.location.reload();
  };

  //h-full max-w-[480px]  md:max-w-[1280px] lg:max-w-[1920px] mx-auto
  return (
    <div className="h-full">
      {showContactModal === true && (
        <Modal
          setShowContactModal={setShowContactModal}
          setSuccessModal={setSuccessModal}
        />
      )}
      {showContactModal === false && successModal === true ? (
        <SuccessModal setSuccessModal={setSuccessModal} />
      ) : null}
      <Routes>
        <Route
          path="/"
          element={
            <ChatProvider>
              <Layout isWhite={false} openContactModel={openContactModel}>
                <NewLandingPage openContactModel={openContactModel} />
              </Layout>
            </ChatProvider>
          }
        ></Route>
        <Route
          path="/how-to-use-QR-terminal"
          element={
            <ChatProvider>
              <Layout isWhite={true}>
                <Container>
                  <RequestAndSend openContactModel={openContactModel} />
                </Container>
              </Layout>
            </ChatProvider>
          }
        />
        <Route
          path="/use-cases"
          element={
            <ChatProvider>
              <Layout isWhite={true} openContactModel={openContactModel}>
                <Container>
                  <UsageCases />
                </Container>
              </Layout>
            </ChatProvider>
          }
        />
        <Route
          path="/for-business"
          element={
            <ChatProvider>
              <Layout isWhite={true} openContactModel={openContactModel}>
                <Container>
                  <ForBusiness openContactModel={openContactModel} />
                </Container>
              </Layout>
            </ChatProvider>
          }
        />
        <Route
          path="/qr-terminal"
          element={
            <ChatProvider>
              <Layout isWhite={true} openContactModel={openContactModel}>
                <QrTerminal openContactModal={openContactModel} />
              </Layout>
            </ChatProvider>
          }
        />

        {/* <Route path="/pay" element={<BogPayment />} /> */}
        <Route path="/tippage" element={<EmoneyTipPage />} />
        <Route path="/tipping" element={<PaymentRedirect />} />
        <Route
          path="/pay"
          element={isMobile ? <BogPayment /> : <BogPaymentWeb />}
        />
        <Route path="/00990099-payTest" element={<BogPayment1 />} />
        <Route path="/terms-and-conditions" element={<TermsPageNew />} />
        {/* <Route path="/privacy/:isMobileApp" element={<PrivacyPage />} /> */}
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route
          path="/contractual-provisions"
          element={<SupplementaryContractualProvisionsPage />}
        />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/e-commerce-test" element={<BogPaymentWeb />} />
        {/* <Route path="/fail" element={<FailPage />} /> */}
        <Route path="/payment-failed" element={<PaymentFailedPage />} />
        <Route path="/downtime" element={<DowntimePage />} />
        <Route path="/apple-app-site-association" onEnter={reload} />
        <Route path="/ecommerce-test" element={<BogPaymentWeb />} />
        <Route path="/custom-tip" element={<CustomTip />} />
        <Route path="/testUfc" element={<TestUfc />} />
        <Route path="/testTbc" element={<TestTbc />} />
        {/* <Route path="/testNewTemrs" element={<TermsPageNew />} /> */}
        <Route path="/redirect" element={<RedirectPage />} />
        <Route path="/lb" element={<LibertyPage />} />
        {/* <Route path="*" element={<div><h1>Page not found</h1></div>} /> */}
      </Routes>
      <ScrollTop />
    </div>
  );
};

export default App;

// import { Routes, Route } from "react-router-dom";
// import React, { Suspense, lazy, useState } from "react";

// import { useEffect } from "react";
// // import EmoneyTipPage from "features/emoneyTip";
// // import SuccessPage from "features/success";
// // import DowntimePage from "features/downtime";
// // import PrivacyPage from "features/privacy/PrivacyPage-old";
// // import TermsPage from "features/terms/TermsPage-old";
// // import TermsPageNew from "features/terms/TermsPage";
// import { useTranslation } from "react-i18next";
// import { useQueryString } from "hooks";
// // import PaymentFailedPage from "features/fail/PaymentFailedPage";
// // import BogPayment from "features/bog/BogPayment";
// // import PaymentRedirect from "features/paymentRedirect/PaymentRedirect";
// import ScrollTop from "components/ScrollTop";
// import "./index.css";
// import { ChatProvider } from "features/chat";
// // import BogPayment1 from "features/bog/BogPayment1";
// import NewLandingPage from "features/newLanding/LandingPage";
// import { Container } from "features/newLanding/LandingPage";
// // import BogPaymentWeb from "features/bog/BogPaymentWeb";
// // import Pending from "features/Pending/Pending";
// // import CustomTip from "features/success/components/CustomTip";
// import { isMobile } from "react-device-detect";
// // import SupplementaryContractualProvisionsPage from "features/SupplementaryContractualProvisions/SupplementaryContractualProvisionsPage";
// import Layout from "features/newLanding/Layout";
// import RequestAndSend from "features/newLanding/RequestAndSendSection/RequestAndSend";
// import Modal from "features/newLanding/Modal/Modal";
// import SuccessModal from "features/newLanding/Modal/SuccessModal";
// // import LibertyPage from "features/newLanding/components/LibertyPage";
// import UsageCases from "features/newLanding/WhyKeepzSection/UsageCases";
// import ForBusiness from "features/newLanding/MerchantsSection/ForBusiness";
// import QrTerminal from "features/newLanding/QrTerminal/QrTerminalPage";
// import Loader from "components/Loader";

// const TestUfc = lazy(() => import("features/bog/TestUfc"));
// const TestEks = lazy(() => import("features/bog/EksTest"));
// const BogPayment = lazy(() => import("features/bog/BogPayment"));
// const BogPaymentWeb = lazy(() => import("features/bog/BogPaymentWeb"));
// const EmoneyTipPage = lazy(() => import("features/emoneyTip"));
// const BogPayment1 = lazy(() => import("features/bog/BogPayment1"));
// const TermsPageNew = lazy(() => import("features/terms/TermsPage"));
// const PrivacyPage = lazy(() => import("features/privacy/PrivacyPage-old"));
// const SupplementaryContractualProvisionsPage = lazy(() =>
//   import(
//     "features/SupplementaryContractualProvisions/SupplementaryContractualProvisionsPage"
//   )
// );
// const SuccessPage = lazy(() => import("features/success"));
// const Pending = lazy(() => import("features/Pending/Pending"));
// const PaymentFailedPage = lazy(() => import("features/fail/PaymentFailedPage"));
// const DowntimePage = lazy(() => import("features/downtime"));
// const LibertyPage = lazy(() =>
//   import("features/newLanding/components/LibertyPage")
// );
// const BogOptimized = lazy(() => import("features/bog/BogOptimized"));

// const App = () => {
//   const [showContactModal, setShowContactModal] = useState(false);
//   const [successModal, setSuccessModal] = useState(false);
//   const { parseQuery: parsedQuery } = useQueryString();
//   const { lng = "", tiny } = parsedQuery;
//   if (tiny) {
//     window.location.href = `https://tinyurl.com/${tiny}`;
//   }
//   const { i18n } = useTranslation();

//   useEffect(() => {
//     if (lng) {
//       const newLng = lng === "ge" ? "ge" : "en";
//       i18n.changeLanguage(newLng);
//     }
//   }, [lng, i18n]);

//   function openContactModel() {
//     setShowContactModal(true);
//   }

//   const reload = () => {
//     window.location.reload();
//   };

//   //h-full max-w-[480px]  md:max-w-[1280px] lg:max-w-[1920px] mx-auto
//   return (
//     <div className="h-full">
//       {showContactModal === true && (
//         <Modal
//           setShowContactModal={setShowContactModal}
//           setSuccessModal={setSuccessModal}
//         />
//       )}
//       {showContactModal === false && successModal === true ? (
//         <SuccessModal setSuccessModal={setSuccessModal} />
//       ) : null}
//       <Routes>
//         <Route
//           path="/"
//           element={
//             <ChatProvider>
//               <Layout isWhite={false} openContactModel={openContactModel}>
//                 <NewLandingPage openContactModel={openContactModel} />
//               </Layout>
//             </ChatProvider>
//           }
//         ></Route>
//         <Route
//           path="/how-to-use-QR-terminal"
//           element={
//             <ChatProvider>
//               <Layout isWhite={true}>
//                 <Container>
//                   <RequestAndSend openContactModel={openContactModel} />
//                 </Container>
//               </Layout>
//             </ChatProvider>
//           }
//         />
//         <Route
//           path="/use-cases"
//           element={
//             <ChatProvider>
//               <Layout isWhite={true} openContactModel={openContactModel}>
//                 <Container>
//                   <UsageCases />
//                 </Container>
//               </Layout>
//             </ChatProvider>
//           }
//         />
//         <Route
//           path="/for-business"
//           element={
//             <ChatProvider>
//               <Layout isWhite={true} openContactModel={openContactModel}>
//                 <Container>
//                   <ForBusiness openContactModel={openContactModel} />
//                 </Container>
//               </Layout>
//             </ChatProvider>
//           }
//         />
//         <Route
//           path="/qr-terminal"
//           element={
//             <ChatProvider>
//               <Layout isWhite={true} openContactModel={openContactModel}>
//                 <QrTerminal openContactModal={openContactModel} />
//               </Layout>
//             </ChatProvider>
//           }
//         />

//         {/* <Route path="/pay" element={<BogPayment />} /> */}
//         <Route
//           path="/tippage"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <EmoneyTipPage />
//             </Suspense>
//           }
//         />
//         {/* <Route path="/tipping" element={<PaymentRedirect />} /> */}
//         <Route
//           path="/pay"
//           element={
//             isMobile ? (
//               <Suspense
//                 fallback={
//                   <div className="h-full">
//                     <Loader />
//                   </div>
//                 }
//               >
//                 <BogPayment />
//               </Suspense>
//             ) : (
//               <Suspense
//                 fallback={
//                   <div className="h-full">
//                     <Loader />
//                   </div>
//                 }
//               >
//                 <BogPaymentWeb />
//               </Suspense>
//             )
//           }
//         />
//         <Route path="/00990099-payTest" element={<BogPayment1 />} />
//         <Route
//           path="/terms-and-conditions"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <TermsPageNew />
//             </Suspense>
//           }
//         />
//         {/* <Route path="/privacy/:isMobileApp" element={<PrivacyPage />} /> */}
//         <Route
//           path="/privacy"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <PrivacyPage />
//             </Suspense>
//           }
//         />
//         <Route
//           path="/contractual-provisions"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <SupplementaryContractualProvisionsPage />
//             </Suspense>
//           }
//         />
//         <Route
//           path="/success"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <SuccessPage />
//             </Suspense>
//           }
//         />
//         <Route
//           path="/pending"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <Pending />
//             </Suspense>
//           }
//         />
//         {/* <Route path="/e-commerce-test" element={<BogPaymentWeb />} /> */}
//         {/* <Route path="/fail" element={<FailPage />} /> */}
//         <Route
//           path="/payment-failed"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <PaymentFailedPage />
//             </Suspense>
//           }
//         />
//         <Route
//           path="/downtime"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <DowntimePage />
//             </Suspense>
//           }
//         />
//         <Route path="/apple-app-site-association" onEnter={reload} />
//         <Route
//           path="/ecommerce-test"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <BogPaymentWeb />
//             </Suspense>
//           }
//         />
//         {/* <Route path="/custom-tip" element={<CustomTip />} /> */}
//         <Route
//           path="/testUfc"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <TestUfc />
//             </Suspense>
//           }
//         />
//         <Route
//           path="/testEks"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <TestEks />
//             </Suspense>
//           }
//         />
//         <Route
//           path="/optBog"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <BogOptimized />
//             </Suspense>
//           }
//         />
//         {/* <Route path="/testNewTemrs" element={<TermsPageNew />} /> */}
//         <Route
//           path="/redirect"
//           element={
//             <div className="spinWrapper">
//               <span className="loader"></span>
//             </div>
//           }
//         />
//         <Route
//           path="/lb"
//           element={
//             <Suspense
//               fallback={
//                 <div className="h-full">
//                   <Loader />
//                 </div>
//               }
//             >
//               <LibertyPage />
//             </Suspense>
//           }
//         />
//         {/* <Route path="*" element={<div><h1>Page not found</h1></div>} /> */}
//       </Routes>
//       <ScrollTop />
//     </div>
//   );
// };

// export default App;
