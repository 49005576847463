import React from "react";
// import Html from "./TermsGe.html";

const En = () => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Sylfaen;
	panose-1:1 10 5 2 5 3 6 3 3 3;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:Georgia;
	panose-1:2 4 5 2 5 4 5 2 3 3;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:"\@Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"Noto Sans Symbols";}
@font-face
	{font-family:Merriweather;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
h1
	{margin-top:24.0pt;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:24.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
h2
	{margin-top:.25in;
	margin-right:0in;
	margin-bottom:4.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:18.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
h3
	{margin-top:14.0pt;
	margin-right:0in;
	margin-bottom:4.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
h4
	{margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:2.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
h5
	{margin-top:11.0pt;
	margin-right:0in;
	margin-bottom:2.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
h6
	{margin-top:10.0pt;
	margin-right:0in;
	margin-bottom:2.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoTitle, li.MsoTitle, div.MsoTitle
	{margin-top:24.0pt;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:36.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:41.45pt;
	text-align:justify;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Sylfaen",serif;}
p.MsoSubtitle, li.MsoSubtitle, div.MsoSubtitle
	{margin-top:.25in;
	margin-right:0in;
	margin-bottom:4.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:24.0pt;
	font-family:"Georgia",serif;
	color:#666666;
	font-style:italic;}
a:link, span.MsoHyperlink
	{color:#0563C1;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:#954F72;
	text-decoration:underline;}
p
	{margin-right:0in;
	margin-left:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"Comment Subject Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"Balloon Text Char";
	margin:0in;
	font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;}
p.MsoRMPane, li.MsoRMPane, div.MsoRMPane
	{margin:0in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{mso-style-link:"List Paragraph Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{mso-style-link:"List Paragraph Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{mso-style-link:"List Paragraph Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{mso-style-link:"List Paragraph Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.ListParagraphChar
	{mso-style-name:"List Paragraph Char";
	mso-style-link:"List Paragraph";}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-link:"Comment Text";}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-link:"Balloon Text";
	font-family:"Segoe UI",sans-serif;}
span.CommentSubjectChar
	{mso-style-name:"Comment Subject Char";
	mso-style-link:"Comment Subject";
	font-weight:bold;}
span.CommentSubjectChar1
	{mso-style-name:"Comment Subject Char1";
	font-weight:bold;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";
	font-family:"Sylfaen",serif;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:0in;text-align:center;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>    
     </span><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>TERMS
AND CONDITIONS OF USING PAYMENT SERVICES</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME LLC (hereinafter
&quot;<b>KEEPZ.ME</b>&quot;) is a payment service provider licensed by the
National Bank of Georgia - 0099 - 7704, which provides users with the services
specified in the &quot;Service Description&quot; chapter.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The terms and conditions of
using the given payment service (hereinafter the &quot;<b>Agreement</b>&quot;)
constitute a legally binding agreement between the KEEPZ.ME user (hereinafter
the &quot;<b>User</b>&quot;) and KEEPZ.ME, and determine the terms of the
existing/establishing relationship, within the framework of which KEEPZ.ME
undertakes to provide the customer with appropriate payment services in
accordance with the legislation and the rules and regulations of international
payment systems.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>This Agreement
is effective from the moment the user starts using the KEEPZ.ME service
(hereinafter the &quot;Effective Date&quot;). Furthermore, when the User starts
using a service that he has not used before, the terms and conditions for using
the corresponding service will automatically apply to him, which is defined in
the chapter &quot;Terms and conditions for using separate services&quot;.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The &quot;General Terms and
Conditions&quot; section of this Agreement applies to all users of KEEPZ.ME; In
the event that any term/provision of the General Terms and Conditions conflicts
with any of the individual Terms of Service, the latter shall prevail</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Along with this agreement,
the existing relationship between KEEPZ.ME and the User is also regulated by
the legal acts applicable to the User, additional agreements/appendices
concluded with the user, terms and conditions, to which the user agrees through
the KEEPZ.ME application, control panel or other electronic form with the rules
and principles of reasonableness, honesty and fairness.</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:0in;text-align:center;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b> PART
</b><b>I. </b><b>GENERAL</b><b> </b><b>TERMS</b><b> </b><b>AND</b><b> </b><b>CONDITIONS</b></span></p>

<ol style='margin-top:0in' start=1 type=1>
 <li class=MsoNormal style='color:black;margin-top:6.0pt;margin-bottom:6.0pt;
     text-align:justify;line-height:115%;layout-grid-mode:char;border:none'><span
     lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:windowtext'><b><span
     style='color:black'>USE OF KEEPZ.ME SERVICES</span></b><b><span
     style='color:black'> </span></b></span></li>
</ol>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The supervisory body of
KEEPZ.ME is the National Bank of Georgia (web page address: https://nbg.gov.ge
address: 0114 Tbilisi, Georgia, Amtsho Street No. 2). For the avoidance of any
doubt, the National Bank of Georgia is not responsible for the non-performance
by KEEPZ.ME of its obligations under this Agreement or any other related
annexes, agreement(s) and applications.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The prerequisite for using KEEPZ.ME's services is
consent/signature of this agreement. If the user starts to use a service that
he has not used before, the relevant terms of service are automatically applied
to him. If the use of new services requires additional verification and/or
submission of additional documentation/information by the user and/or meeting
the requirements/criteria defined by KEEPZ.ME or the relevant normative act, the
User will be able to use the new service only after following all the
instructions of KEEPZ.ME and meeting the relevant requirements and criteria.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
services can be used by persons who are considered/permitted by separate
payment service conditions. However, it is not allowed to request
registration/service by the user or the user's representative on behalf of or
for the benefit of a user who has been denied access to the KEEPZ.ME service on
the grounds specified in this Agreement.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>By agreeing to this agreement, the user and his
representative (if any) agree and confirm that the representative, taking into
account the requirements of the relevant legislation, is an authorized person, who
is authorized to act on behalf of the user and on his behalf in the mentioned
legal proceedings (including, but not only, to submit the information necessary
for receiving the service on behalf of the user and consent to the given
contract in such a way that it is legally binding for the user). KEEPZ.ME may,
if necessary, request the provision of additional information/documentation
confirming the authority of the user's representative</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In order for KEEPZ.ME to comply with the mandatory
requirements of the existing legislation on the promotion of the prevention of
money laundering and the financing of terrorism, as well as to ensure the
prevention of possible fraudulent activities, KEEPZ.ME is authorized, To
request such data and/or documents, according to which it will be possible to
identify/verify the user and/or obtain important information about the user,
which is necessary for the proper provision of KEEPZ.ME services.</span><span
style='color:black'> </span><span style='color:black'>The specific data and/or
documents to be submitted will be described both in the relevant service
conditions and in another message/document sent to the user about the need to
perform identification or other verification procedures.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The User confirms and unconditionally guarantees that the
information provided to KEEPZ.ME within the framework of the relationship
contemplated by this Agreement (including for the purposes of Article 1.4)
is/will be correct and unmistakable. </span><span style='color:black'>Any damage
caused by submitting incorrect data is the responsibility of the user.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.gjdgxs"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>1.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>By agreeing to
this Agreement, the User acknowledges and authorizes KEEPZ.ME to transfer the
information about the User (including URLs); information about persons included
in management bodies; user addresses; the amount of money and transactions
traded within the KEEPZ.ME service and any other information/documentation) to
the partner bank and/or international payment systems and/or supervisory,
controlling and/or registration bodies, state or local self-government bodies
and legal entities created by them.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.7<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>By agreeing to this agreement, you also express your consent to
the personal data processing policy of &quot;Keepz.me&quot; LLC users, which is
available at the following link - </span><span lang="GEO/KAT"><a
href="https://app.keepz.me/privacy"><span style='font-family:"Sylfaen",serif'>https://app.keepz.me/privacy</span></a></span></p>

<ol style='margin-top:0in' start=2 type=1>
 <li class=MsoNormal style='color:black;margin-top:6.0pt;margin-bottom:6.0pt;
     text-align:justify;line-height:115%;layout-grid-mode:char;border:none'><span
     lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:windowtext'><b><span
     style='color:black'>REPRESENTATIONS AND WARRANTIES</span></b></span></li>
</ol>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
Parties represent and warrant that: </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>At the time of signing this Agreement, they are/will be
capable of acting (including those created in accordance with the law).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>They have
full authority (or obtain appropriate authority) to sign/confirm and perform
the obligations under this Agreement or any other related agreement(s) and
applications.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Fulfillment of the terms stipulated in this agreement will
not violate the terms of other agreement(s) or their other obligation(s) or any
kind of law, regulation, rule, decree, a court judgment, order, reference,
court order or any restriction imposed by a government, regulatory authority,
judicial or arbitral authority, which the relevant party is responsible for
complying with.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
performance of any operation under the terms of this Agreement will not
conflict with or violate any agreement, contract, license or other agreement
applicable to either party.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Their actions are not/will be aimed at deceiving the other
party. Taking into account the mentioned principle, the document and/or
information presented by them to the other party for the conclusion of the
contract or based on it and at the moment of its presentation, is/will be
unmistakable, accurate and complete. In addition, they are aware that providing
false documents and/or information is punishable under the law.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>They <span
style='color:black'>are not engaged in or participating in any illegal activity
under the laws of any country (including money laundering, arms trafficking,
terrorism or any other illegal activity).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>They <span
style='color:black'>have obtained and will maintain throughout the term of this
Agreement all licenses and permits necessary for the sale of their
products/services and for the performance of any and all obligations set forth
in this Agreement.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Any and all services offered to their customers are fully
compliant with the requirements of applicable law in any jurisdiction
applicable to each party.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The aforementioned statements and guarantees are valid
until the parties fully and properly fulfill their obligations under the
Agreement and/or other related agreements, regardless of the full or partial
termination of the Agreement and/or other related agreements.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>After
the entry into force of this Agreement, each party shall immediately notify the
other party in writing of such circumstances that may lead to a violation of
the guarantees provided for in this article or contradict the aforementioned
statements.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The parties represent and warrant that the contract is
concluded on the basis of the above-mentioned statements and guarantees and
based on them, they enter into and fulfill the obligations provided for in the
contract.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>2.5 In the event of a breach of the foregoing statements
(without limiting the right to seek damages arising out of such breach of
warranty), the breaching party agrees to indemnify and hold the other party
harmless from any damages, claims, costs, legal proceedings and any other
liability that may arise as a result of such breach, including after the termination/expiration
of this Agreement</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>LIABILITY OF THE PARTIES</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Unless otherwise specified in the Agreement and/or other
related agreements, the user agrees and declares that in the process of
implementing the relevant payment service, </span><span style='color:black'> KEEPZ.ME
is fully released from any liability to the user (including, without
limitation, any direct or indirect damages (losses) and/or the obligation to
compensate for lost profits, business, income or planned savings), </span><span
style='color:black'>which is caused without KEEPZ.ME's willful or gross
negligence, therefore, KEEPZ.ME is obliged to compensate the user only for the
cost/damage caused by KEEPZ.ME's fault (intentional or gross negligence) including,
but not limited to, as a result of non-execution, incorrect or late execution
of a payment operation and/or payment order.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The user acknowledges that KEEPZ.ME provides the payment
services specified in this agreement and does not represent a seller, buyer,
dealer, supplier, distributor, manufacturer, broker, agent and, accordingly, is
not responsible for the obligations assumed by the user to third parties,
except for those obligations that KEEPZ.ME is responsible for fulfilling within
the framework of this agreement</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The user takes full responsibility for the quality of the product/service
sold by him (if any), and/or the content of any information submitted to third
parties, also on the content of the information that the user places on the
Internet in connection with the sale of his product/service, he is also
obliged, based on the request sent by KEEPZ.ME to the e-mail, to delete any
information about KEEPZ.ME posted by the user on the Internet.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The User shall indemnify and hold harmless KEEPZ.ME from
all claims and direct, indirect or consequential liabilities (including loss of
profits, loss of business, and similar losses),</span><span style='color:black'>
</span><span style='color:black'>from the costs, proceedings, losses and
expenses (including the financial liability, legal and other professional fees
and expenses) incurred by KEEPZ.ME by any third party or body, taking into
account that</span><span style='color:black'> </span><span style='color:black'>Said
liability, loss, damage, loss and expense was caused or related to the neglect,
violation, non-performance or late performance of the terms and conditions of
this Agreement by the user or a person related to him.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.30j0zll"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>3.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>In
any event, KEEPZ.ME's total liability to the User for any damages or expenses
of any kind shall be limited to the amount of the total commission paid by the
User to KEEPZ.ME during the period of cooperation.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Any due or undue obligations/payments to be fulfilled by
the customer to KEEPZ.ME (including any kind of contractual and/or
non-contractual relationship between the parties, as well as arising on the
basis of a request purchased by KEEPZ.ME from a third party) in order to ensure
full and timely performance and, accordingly, to realize the rights and
requirements of KEEPZ.ME, KEEPZ.ME is authorized at its own discretion, without
further/additional consent and/or acceptance and/or informing of the user, not
to fulfill any obligation to the user (including not to pay the user any amount
owed to him, which will be considered (deducted) in the corresponding amount
from the account of the obligation/payment to be performed by the user to
KEEPZ.ME).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>INFORMATION SECURITY</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.1fob9te"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>4.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Each party will maintain commercially reasonable administrative,
technical and physical controls to protect data in its possession or control
from unauthorized access, accidental loss and unauthorized alteration.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME ensures that the services under its control are
implemented in accordance with PCI-DSS and legal requirements (including, but
not limited to, strong user authentication).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The user is obliged to prevent any unauthorized access to
the KEEPZ.ME account and to ensure that it is not used or modified by third
parties. In addition, in the event of unauthorized access (which, among other
things, implies the existence of a suspicion that the account data has been
lost, stolen or the account has been/may be misused) the User shall promptly
notify and cooperate with KEEPZ.ME, including by providing information that
KEEPZ.ME requests.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The customer is responsible and accountable for the
activities that are carried out using his KEEPZ.ME account and which are not
within the control of KEEPZ.ME. In addition, in addition to the damage caused
by KEEPZ.ME's violation of the obligation specified in the contract and this
agreement, the user is responsible for all the resulting losses/damages, which
occurs as a result of stolen or lost payment or account data being used.
KEEPZ.ME does not insure the user against losses that may occur due to fraud or
other circumstances.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>CONFIDENTIALITY</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The parties are obliged to protect the confidentiality of
any information received from the other party, both during the period of
validity of the contract and after the end of the contractual relationship.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The above limitation on confidentiality shall not apply to
information or disclosure of information:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>which was known to the party receiving the information
without violation of the law before the provision of the relevant information
by the other party;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>If the information is disclosed by the parties to comply
with and fulfill the requirements of the legislation (including by any party to
exercise its rights in court (including the arbitration court)) and/or based on
the request of the supervisory body and/or the partner bank;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>which can be obtained from other sources;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>If information is disclosed to a third party a) by written
agreement of the parties, in which case the party giving the information is
fully responsible for protecting the confidentiality of the information
provided by the third party or b) independently of any party.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Additional rights and obligations of the parties related to
privacy and processing of personal information are provided on our website,
&quot;Privacy Policy&quot;, which is an integral part of this Agreement and
operates together with it. </span></span><i><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'> </span></i><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'>     </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>INTELLECTUAL PROPERTY AND MARKETING COOPERATION</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>All intellectual property rights of KEEPZ.ME shall remain
its sole property and the User shall not assert any claim against them at any
time, including after termination of this Agreement. </span></span><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>User
shall use KEEPZ.ME's intellectual property rights only to the extent permitted
by this Agreement and only during the term of this Agreement. User shall not
take any action inconsistent with KEEPZ.ME's intellectual property rights and
shall exercise reasonable care to protect such rights.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>All intellectual property rights of the User shall remain
the sole property of the User and KEEPZ.ME shall not assert any claim to them
at any time, including after termination of this Agreement. Pursuant to this
Agreement and during the term of the Agreement, the User grants to KEEPZ.ME a
non-exclusive, royalty-free right to use its intellectual property, including
any user logos, trademarks, trade names and/or other intellectual property
worldwide. In addition, upon receipt of a corresponding written request by the
user, </span><span style='color:black'>KEEPZ.ME is obligated to immediately
terminate the use of the User's intellectual property, except in cases where
such termination would result in a violation of KEEPZ.ME's legal obligation.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The user is obliged to follow the terms and conditions of
use of intellectual property rights transferred to him by KEEPZ.ME.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME reserves the right at any time to require the User
to stop publishing, distributing and/or otherwise restrict the User's use of
the intellectual property of KEEPZ.ME and/or its affiliates.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The User is not authorized to use KEEPZ.ME's trade name
and/or trademark without its prior written consent.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The obligations defined in this article remain in force and
apply even after the termination/expiration of the contract.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>SERVICE COST</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.3znysh7"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>7.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>The
fee for using the respective service is defined in the terms and conditions of
using a separate service. In addition, based on the mutual written agreement of
the parties, it is possible to set a special service fee different from the
standard fee; Different service fees will be imposed within the framework of
business expansion, taking into account the turnover of the user and the number
of transactions.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.2et92p0"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>7.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>In
addition to the standard service fee, in certain cases, an additional fee may
be charged, the payment of which is the customer's responsibility. An
additional commission will be imposed, according to the legislation, Among
them, taking into account the requirements/restrictions related to fees of the
Law &quot;On Payment System and Payment Services&quot; and only in the case
when the mentioned is due to objective circumstances, including depending on
the cost structure related to the transaction (for example, interbank transfer
fees)</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>TAXES</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
Merriweather;color:black'>8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The user is responsible for fulfilling tax obligations to
the state or other authority. The customer is also responsible for paying any
unpaid taxes (which include without limitation value added tax), Customs Tax
that may be imposed on any liability that may arise in connection with the
management of the Account(s) or any or all of the Services specified in this
Agreement (collectively, “Tax Liability”); In addition, KEEPZ.ME shall itself
be responsible for the payment of any tax or liability related to the commission
amount received from the user. If KEEPZ.ME is subject to any tax or penalty due
to the User's tax liability under the law, the User shall promptly indemnify
KEEPZ.ME against all such liabilities unless the imposition of such charge or
penalty is caused by KEEPZ.ME's willful and/or gross negligence.</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
Merriweather;color:black'>8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The parties must comply with the legal requirements of all
other countries if they apply or may apply to them in any way.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>VALIDITY, SUSPENSION AND TERMINATION OF THE AGREEMENT</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The Agreement enters into force on the date of its
execution and is valid for life.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The parties may additionally confirm the fact of concluding
the agreement by signing the printed version of the general conditions and/or
its annexes, and to avoid any doubt, the parties confirm that the agreement,
regardless of the date of signing the printed version, is valid from the date
of its implementation.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
rules for termination and/or suspension of the contract by the parties in terms
of specific services are determined by separate terms of service.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Full or partial termination of the agreement and/or other
related agreements does not exempt the user from full and timely performance of
the obligations imposed by the agreement and/or other related agreements and/or
established by law </span><span style='color:black'>(from payment) to the
moment of forced or voluntary performance of such obligation.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ADDENDUMS AND AMENDMENTS</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME is authorized, at any time, by placing relevant
information on the website and/or in another form acceptable to KEEPZ.ME,
unless otherwise specified by law, without additional consent and acceptance of
the user, to change any of the terms of the agreement (including and (not just
any KEEPZ.ME product). </span><span style='color:black'>In addition, the
mentioned changes can affect both a certain group of users and a specific user.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>If the change/addition to the contract is made in favor of
the customer, and/or does not worsen the situation of the customer, and/or the
change/addition refers to a new payment service that does not replace and/or
replace the payment service provided for in the contract, </span><span
style='color:black'>KEEPZ.ME is under no obligation to notify the User of such
change/addition.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In the event that a notice period is provided for prior to
the entry into force of a change/addition provided by law, any such
change/addition shall be deemed to have been agreed upon by the User unless the
User notifies KEEPZ.ME prior to the entry into force of the change/addition, that
does not agree with the change/addition. Before the implementation of the
changes, the user is entitled to terminate this agreement immediately, and, in
turn, in the event of the user's rejection of the changes, KEEPZ.ME is entitled
to terminate the agreement immediately after the date of implementation of the
changes.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Any changes and/or additions made to the Agreement
constitute an annex to the Agreement and an integral part thereof.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>FORCE MAJEURE</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>11.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>None of the parties shall be responsible for
non-fulfilment, untimely and/or improper performance of obligations under this
Agreement, if said non-fulfilment, improper or untimely performance is due to
the occurrence and/or action of force majeure circumstances. The said
obligation comes into force upon cancellation of the force majeure
circumstances.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>11.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Circumstances of force majeure include natural disasters,
fire, power outages, equipment malfunctions, software damage, acts of war,
administrative-legal acts </span><span style='color:black'> </span><span
style='color:black'>and/or other circumstances insurmountable to either party
and beyond its control, which: a) are not related to errors and negligence of
either party, b) started or developed after the entry into force of the
agreement and c) has a direct and immediate impact on the full and/or timely
performance of obligations under the agreement.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>11.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The party affected by the force majeure circumstances is
obliged to notify the other party in writing of the force majeure circumstances
immediately, no later than within 3 (three) banking days after the occurrence
of the said circumstances and</span><span style='color:black'> </span><span
style='color:black'>about their occurrence and take necessary measures to
reduce the negative consequences caused by these circumstances. Similar
requirements also apply to the notice of termination of force majeure
circumstances and their consequences.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>11.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In case of occurrence of force majeure circumstances, the
term of performance of the obligations provided for in this Agreement will be
extended according to the period of force majeure.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ASSIGNMENT AND OTHER RELATIONSHIPS</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>12.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The User, without the prior written consent of KEEPZ.ME, is
not authorized to assign, transfer, transfer, sub-contract or entrust its
contractual rights and obligations. </span><span style='color:black'>KEEPZ.ME's
refusal excludes the possibility of carrying out any of the above-mentioned
actions and/or transactions, and accordingly, actions and/or transactions
carried out in violation of this rule are void and do not generate legal
consequences, except for cases explicitly defined by law.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>12.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME may at any time, without prior written consent,
assign, transfer, transfer, sub-contract or entrust its contractual rights and
obligations.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>COMMUNICATION BETWEEN THE PARTIES</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Communication with the User:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME will communicate with the user at the
addresses/contact data specified in the agreement and/or other related
agreements (or any other address/contact data that the user informs KEEPZ.ME in
writing). </span><span style='color:black'>The user is obliged to inform
KEEPZ.ME about the change of the mentioned address(es) or any data in a timely
manner, otherwise, the relationship carried out by KEEPZ.ME at the mentioned
address (sending a message, etc.) will be considered as completed.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The notice is considered delivered on the day it is
received by the addressee, if the receipt of the notice is confirmed by the
addressee (including by electronic document, receipt, other means corresponding
to the notice, etc.). Unless receipt of a notice is acknowledged by the
addressee, any such notice shall be deemed to have been properly sent and
received:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In the case of sending a written notice or telegram by courier
or post, if the notice is sent by KEEPZ.ME, within 3 (three) calendar days
after sending, or on the date of confirmation of delivery (whichever is
earlier).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In case of sending by fax, e-mail and/or other electronic
means, on the second banking day from the date of sending.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The message is considered received even if the message of
KEEPZ.ME is returned to the address/contact data sent due to the lack of
location of the addressee of the message, the addressee refuses to receive the
message, or avoids receiving it.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In the event that more than one user is a party to the
agreement and/or other related agreement, any message sent by KEEPZ.ME to one
of the users will be considered as a message sent to all users. And receiving a
message by one of the users means receiving the corresponding message by all
users.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'> Communication with KEEPZ.ME</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME can be contacted via email: info@keepz.me as well
as live chat on the KEEPZ.ME platform (at any time during the following
periods: Monday to Friday 10:00 a.m. to 7:00 p.m. to hours, except for public
holidays declared according to the legislation of Georgia).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Submissions will be deemed accepted from the date KEEPZ.ME
confirms receipt of the relevant notice or takes appropriate action.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>CLAIMS AND DISPUTES</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>14.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>14.1 Claims arising from the Agreement and/or other related
agreements may be submitted by the parties to each other in writing and/or
orally. The party receiving the claim is obliged to fully or partially satisfy
the claim within 15 (fifteen) calendar days after receiving the claim,  or
notify the other party in writing and/or orally of its refusal to satisf</span><span
lang=EN-US style='color:black'>y.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>14.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In accordance with Article 14.1 of this Agreement, the
procedure for submission, acceptance and review of claims/complaints submitted
by the user to KEEPZ.ME is provided on the website of KEEPZ.ME, which is an
integral part of this Agreement and operates together with it.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>14.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Any dispute arising out of the Agreement and/or other
related agreements (including those relating to the existence, interpretation,
performance and enforcement of the Agreement and/or other related agreements) it
is resolved by negotiation. In case of unresolved dispute, the parties apply to
the court.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>OTHER TERMS</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>This Agreement constitutes the entire agreement between the
parties and supersedes and supersedes all prior agreements, conditions,
warranties, assurances, representations or understandings between them, whether
written or oral, relating to the subject matter hereof.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>For the avoidance of doubt, the parties are independent
contractors and this Agreement shall not be construed as establishing a joint
venture and/or agency relationship between KEEPZ.ME and the User.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME is not responsible for the non-receipt of messages
by the user, if it was sent to the address or contact data specified by the
user in advance.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME's delay in exercising its rights does not mean
that KEEPZ.ME waives its rights; Also, the partial or complete non-use of any
right does not mean a restriction on the full use of this right in the future.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Article(s), paragraph(s) and subsection(s) of the Agreement
are fully applicable to the Annex to the Agreement and other related
agreements. In addition, in the event of a contradiction or inconsistency
between the terms of the agreement and the annex to the agreement or any other
agreement related to it, The terms of such addendum and other related agreement
shall prevail over the matters to which such addendum or other related
agreement is made.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The parties confirm that the content of the agreement
accurately expresses the will of the parties and that their will was expressed
as a result of a reasonable judgment of the content of the agreement and not
only from the word-for-word meaning.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Any and all rights granted to a party as a result of the
other party's breach of the Agreement, other related agreements and/or
legislation in whole or in part, are cumulative and will be in addition to all
other rights granted by the Agreement, other related agreements and/or
legislation.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Non-use of the rights granted to it by the other party in
connection with the full or partial violation of the agreement, other related
agreements and/or legislation by one of the parties shall not apply to any
subsequent breach of the Agreement, other related agreements and/or
legislation.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The invalidity of any article(s), paragraph(s) and/or
sub-paragraph(s) of the Agreement and/or other related agreements shall not
result in the invalidity of the entire Agreement and/or other related
agreements and/or their other article(s). the invalidity of clause(s) and/or
sub-clause(s). Instead of the invalid provision, such a provision is used,
which will more easily achieve the purpose stipulated by the agreement and/or
other agreements related to it (including the invalid provision).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Words used in the singular in the Agreement and/or any
other related agreement shall mean the plural and vice versa, as the context
requires.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.11<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Article(s), clause(s) and/or sub-clause(s) of the Agreement
and/or other related agreements are numbered and titled for convenience only
and this fact shall not be of significance for the interpretation of the
Agreement and/or related agreements.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.12<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The contract and/or other related contracts with its
obligations and rights apply and are binding for the successors/heirs of the
parties, if the legislation does not provide otherwise, taking into account the
content of the contract and/or other related contract and/or its/their
article(s), clause(s) and sub-clause(s).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.13<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The contract is interpreted and regulated in accordance
with the legislation. In the cases that are not stipulated by the agreement
and/or other agreements related to it, The parties shall be guided by the norms
governing the relevant relationship established by the legislation and/or by
the additional agreed conditions.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.14<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The agreement and/or other related agreements are drawn up
in both Georgian and English languages. When interpreting the contract and/or
other related contract, priority is given to the text written in the Georgian
language. The mentioned norms apply, Also, on the relationship between the
parties and/or the drafting or interpretation of the agreement and/or other
related agreement, or any other document.</span></span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:.75in;text-align:center;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='font-size:12.0pt;line-height:115%'>PART</span></b><b><span
style='font-size:12.0pt;line-height:115%'> II. </span></b><b><span
style='font-size:12.0pt;line-height:115%'>DEFINITIONS</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Unless otherwise defined in
the Agreement and/or other related agreement, or unless otherwise derived from
its context, the following definitions in the Agreement and/or other related
agreement shall have the following meanings:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Party</b><b> </b>-
KEEPZ.ME or the User, separately.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The Parties</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>- KEEPZ.ME and the User
jointly.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Payment services</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - services provided by
KEEPZ.ME to the user, considered in the &quot;Description of Services&quot;
section of KEEPZ.ME.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Legislation</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - valid legislative and
sub-legal normative acts of Georgia and international treaties and agreements
included in the system of normative acts of Georgia.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Card operation</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - the operation of debiting
the payment from the user's card account and crediting it to the settlement
account.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Settlement account</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - a nominal ownership
account opened in a partner bank, where the customer's funds are placed
separately.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Account</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - registered by the
National Bank of Georgia</span><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span></b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><span style='color:black'>A unique way to
register cash, securities, electronic money or convertible virtual asset with a
commercial bank, microbank, brokerage company, payment service provider or
virtual asset service provider.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Electronic money</b><b>- </b>In
the process of providing payment services by KEEPZ.ME, the equivalent value of
cash received from or given to the user for payment operations, which can be
stored on the payment instrument (on an electronic wallet) and which KEEPZ.ME
and others recognize as a means of payment. In exchange for electronic money,
the user has the opportunity to receive/transfer funds of the corresponding
nominal value in accordance with the terms of this agreement.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Electronic wallet</b><b>-
</b>If any, an electronic money instrument created by KEEPZ.ME – a payment
instrument that allows the use of electronic money and which is used by the
user when receiving payment services. KEEPZ.ME opens an e-wallet
account/payment account for the user and provides services to it under the
terms and conditions specified in the agreement. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The payment account is
opened by the user based on the presentation of documents/information necessary
for the relevant service.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span style='color:black'>Payment
account</span></b><span style='color:black;background:white'> - </span><span
style='color:black'>A virtual (electronic) wallet, a unique electronic money
account opened in the KEEPZ.ME payment system in the name of the user, which is
a registered electronic account and payment instrument, and the manner of its
use is regulated by this agreement.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>payment operation</b> - A
cash deposit/deposit, transfer or withdrawal operation initiated by the payer
or payee.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Customer </span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>- the person who pays the User
for the product/service.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Card </span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>- card/cards issued by
financial institutions included in the local payment system and/or
international payment system and/or any other type of card instrument.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Payment order</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - an order given by the
user to KEEPZ.ME to carry out a payment operation.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Payer</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> – a person who issues a
payment order and/or agrees to issue a payment order.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Recipient</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> – the person in whose favor
the payment operation is carried out.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Third party</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - any legal or natural
person (including legal entities under public law) and non-entrepreneurial
(non-commercial) legal entities (non-commercial) other than KEEPZ.ME and the
user.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Related Person</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - a branch, contractor,
employee, etc. of any party.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Suspicious operation</b>
- A transaction that is carried out by a person included in the international
or local stop list in connection with the legalization of illegal income or a
transaction that is contrary to the existing international regulations
regarding the prevention of legalization of illegal income, the legal requirements
of Georgia and/or the requirements and procedures established by the partner
bank and/or KEEPZ.ME.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Card Issuer</b> - A
provider that issues a payment instrument that can be used to initiate a card
transaction.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Internet user portal</b>-
The user's website, mobile application and/or other electronic-technical means,
which has a connection to the Internet and allows the sale of products/services
through the Internet.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Trading platform</b> - One
of the forms of e-commerce services, when two or more participants of different
trading platforms sell their products/services from the Internet portal of one
trading/service facility owned by the trading platform organizer.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Participant of the
trading platform</b>- a person who sells his product/service through the
Internet portal of a trade/service facility owned by the organizer of the trade
platform and who has signed a relevant contract with the organizer of the trade
platform.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Trading platform organizer</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> – trading/service facility,
which, as a commercial intermediary, manages the trading platform.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>PCI DSS</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>- (Payment Card Industry
Data Security Standard) - a card security standard that contains requirements
for safe storage, transfer and/or disposal of card data and aims to help
organizations in the process of protecting customer data.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Intellectual property
rights</b>- means any (i) copyrights, patents, know-how, domain name rights,
trademark rights, trade names, service marks and designs (whether or not
registered); (ii) any application for registration or the right to apply for
registration of any of the foregoing, and (iii) all other intellectual property
rights or any other form of equivalent or similar protection anywhere in the
world.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Authorization page</b> - The
website(s) owned by KEEPZ.ME's partner bank and/or its affiliate, where the
customer enters the card details or the username and password to access the
authorization page or the relevant information required for the bank transfer.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>International payment
system</b> - “VISA&quot;, &quot;MasterCard&quot; and/or other alternative
payment system, which with the combination of common rules and standard
procedures, ensures the processing of money transfer operations and the
corresponding settlement.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Regulation of the
international payment system</b> - Necessary norms developed by the
international payment system and established for participation in the
international payment system, in which changes are made periodically based on
the sole decision of the international payment system.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Payable</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - Cash paid by the customer
to the customer to compensate for the cost of products/services.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Partner bank</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - a bank registered in
accordance with the legislation of Georgia, through which KEEPZ.ME provides its
users with the services specified in these terms.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Business Day/Working Day</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - day, excluding Saturdays
and Sundays and statutory holidays. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Incident cycle</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - the process of exchange
of settlement-financial and other operations between the parties involved in
the settlement in order to clarify the circumstances of the performance of the
card operation(s).</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Acquiring</b> - payment
service, which KEEPZ.ME provides based on these rules and which includes
receiving and processing a payment operation, as a result of which funds are
transferred to the user.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Money transfer</b> - a
payment service provided by KEEPZ.ME based on these rules and which is provided
in the corresponding subsection of these rules</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>Money transfer</b> - a
payment service provided by KEEPZ.ME based on these rules and which is provided
in the corresponding subsection of these rules</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>&quot;Admin Panel“</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - an interface on the
KEEPZ.ME platform through which users can manage their tax account and receive
information on payments made / received</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'>.</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:0in;text-align:center;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>PART</b><b>
III.      </b><b>SERVICE DESCRIPTION</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.25in;text-indent:-.25in;line-height:115%;layout-grid-mode:char;
border:none'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>Acquiring services</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME, as part of the
Acquiring Service, allows users to receive payments using the following
channels:</span></p>

<ol style='margin-top:0in' start=1 type=1>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify;line-height:115%;layout-grid-mode:char;border:none'><span
     lang="GEO/KAT" style='font-family:"Sylfaen",serif'>      QR payment and
     e-mail Commerce</span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify;line-height:115%;layout-grid-mode:char;border:none'><span
     lang="GEO/KAT" style='font-family:"Sylfaen",serif'>API INTEGRATION</span></li>
</ol>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>&quot;API Integration&quot;
is primarily used by customers who sell goods/services through a
website/application they own or rightfully own. „&quot;No-code solution&quot;
was created for customers who deliver their goods/services through social
networks or other types of communication with the client.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char;
border:none'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'> QR
payment and e-mail Commerce     </span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.55in;text-align:justify;text-indent:-.3in;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></b><span
lang="GEO/KAT"><b>     </b><b><span style='font-family:"Sylfaen",serif'>QR </span></b><b><span
style='font-family:"Sylfaen",serif'>PAYMENT</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char;
border:none'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Using the
QR payment method, customers can purchase an item by scanning a QR code,
namely, the customer will scan a QR code that is generated individually for the
customer and is a unique code entered by the customer from the control panel of
KEEPZ.ME (The user prints out the QR code, or opens the KEEPZ.ME application to
the payer, using the so-called &quot;Memorization as a picture&quot;
functionality, so that the QR code is available to the payer-client).  After
scanning the QR code, the client will be redirected to a page indicating the
cost of the selected item / service, chooses a payment method (including
linking a card, open banking (if any) Apple Pay or Google Pay) and completes
the corresponding payment operation.</span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.55in;text-align:justify;text-indent:-.3in;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></b><span
lang="GEO/KAT"><b>     </b><b><span style='font-family:"Sylfaen",serif;
color:black'>FB / Instagram </span></b><b><span style='font-family:"Sylfaen",serif;
color:black'>SHOP</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>If the user has a
Facebook/Instagram store, online card payments can be integrated with the
Facebook/Instagram store via a special integration link on the user's
dashboard.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>In case of such an
integration method, the user only places/uploads the products on the user
panel, after which they are automatically displayed in the corresponding
digital store, from where the client can directly purchase them.</span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.55in;text-align:justify;text-indent:-.3in;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT">     <b><span style='font-family:"Sylfaen",serif;
color:black'>LINK PAYMENT</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Pay per link allows the user
to create a one-time or static link. The mentioned link will be automatically
sent to the client, who must enter the requested information and complete the
payment through the card.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.2 API INTEGRATION </span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Through API integration, the
user can enjoy the following products.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>1.2.1 </b><b>One time
payment</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The one-time payment concept
serves the sole purpose of deducting the specified amount from the client's
card.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>1.2.2 </b><b>Payment on
your website</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>&quot;Payment on your
website&quot; option allows customers to make payment on their website and thus
avoid redirecting the customer to an additional/third party website and the
formalities/efforts associated with such a procedure.</span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT"><b><span style='font-family:"Sylfaen",serif;
color:black'>OPEN BANKING</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:115%;layout-grid-mode:
char;border:none'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>2.1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>Payment initiation services</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Through the payment
initiation service, KEEPZ.ME ensures, at the request of the user, the
withdrawal of money from the user's account and the transfer of money to
another account or card.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:115%;layout-grid-mode:
char;border:none'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>2.1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>Access to account information</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Through access to account
information, KEEPZ.ME provides, at the user's request, one-time, permanent or
periodic updates of information regarding the balance in the user's account,
including the addition and availability of information to the KEEPZ.ME
dashboard.</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:.25in;text-align:justify;text-indent:-.25in;
line-height:115%;layout-grid-mode:char'><b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT"><b><span style='font-family:"Sylfaen",serif;
color:black'>MONEY TRANSFERS</span></b></span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:21.3pt;text-align:justify;text-indent:-.3in;
line-height:115%;layout-grid-mode:char'><b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>3.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><span lang="GEO/KAT">     </span><b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>MPNEY TRANSFER TRANSACTION</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>Through
the KEEPZ.ME application, the user initiated by a natural person from the card
account of the card issued by the financial institutions operating in the
territory of Georgia Another user of KEEPZ.ME, an individual, to the card
account issued by financial institutions operating in the territory of Georgia,
money transfer operation in national currency („</span><b><span
style='color:black'>Money Transfer Transation</span></b><span style='color:
black'>“ </span><span style='color:black'> </span> </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ADDITIONAL FUNCTIONS</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>In addition to the products
discussed in the first and second parts of this document, KEEPZ.ME additionally
provides the following functionalities:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Noto Sans Symbols";color:black'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>refund,
partial refund (&quot;refund operation&quot;) in case of relevant
preconditions.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Noto Sans Symbols";color:black'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>Pre-authorization - temporarily blocks funds on the client's card
before the transaction.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Noto Sans Symbols";color:black'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Get Transaction Information – Allows users to get
information about the status of any transaction. Transaction status can also be
requested manually, although all payment types have so-called
&quot;hookUrl&quot; that automatically sends the transaction status to the
user's server.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;line-height:115%;layout-grid-mode:char'><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'>Additional services are directed to the
products specified in this description.</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:0in;text-align:center;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black;background:white'>PART</span></b><b><span style='color:black;
background:white'> IV. </span></b><b><span style='color:black'>Terms and
conditions for using separate services</span></b></span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:2.0in;text-indent:-.25in;line-height:115%;layout-grid-mode:
char;border:none'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>I.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT"><b><span style='font-family:"Sylfaen",serif;
color:black'> Acquiring Services</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>INTRODUCTION</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;line-height:115%;layout-grid-mode:char;
border:none'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>This chapter defines the terms and conditions of using the
acquiring service, within the framework of which, based on the present
agreement and the corresponding agreement signed with the partner bank, it is
provided by the bank's electronic and technical means, Carrying out a card
operation through the cardholder's payment instrument (card) and the
corresponding settlement.</span><span style='color:black'> </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>Eligibility</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;line-height:115%;layout-grid-mode:char;
border:none'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Acquiring services can be used by an entrepreneurial
society registered in accordance with the legislation of Georgia or another
organizational structure or an individual entrepreneur (&quot;that&quot;),
which carries out activities in accordance with the legislation of Georgia.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>REGISTRATION</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.1<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>     <span style='color:
black'>In order to receive the acquiring service, the user must register on the
website www.keepz.me and provide relevant information about the user.</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.2<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>3.2
In order for KEEPZ.ME to comply with the requirements of the existing
legislation on the promotion of the prevention of money laundering and the
financing of terrorism, as well as to ensure the prevention of possible
fraudulent activities, The next stage of successful registration on the website
for the user is to fill out the form placed on the control panel, within the
framework of which he must provide information about himself, his activities
and shareholders (hereinafter &quot;Registration and Service
Application&quot;). The user unconditionally guarantees that the information
provided by him is correct and valid.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.3<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>During
the validity period of this Agreement and after it (if it is necessary to
comply with the regulatory legislation and/or requirements submitted by the
partner bank) KEEPZ.ME is entitled to request from the User and in such case,
the User is obliged to KEEPZ.ME, within the time period requested by him, </span><span
style='color:black'> </span><span style='color:black'>submit any information
related to the User's business, including without limitation information about
the User's property, the User's articles of association, the User's founders,
about the beneficial owners, directors, subsidiaries and affiliates and
affiliates of the user.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.4<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>The
user is obliged to immediately notify KEEPZ.ME of any changes (including
changes made and/or to be made in the corporate structure, list of products
and/or services). The User shall indemnify KEEPZ.ME against any damages and/or
losses that may result from the User's failure to notify of the aforementioned
change in its corporate structure and/or operations. In addition, the User
shall, upon request by KEEPZ.ME, Promptly provide any additional
information/supporting documentation required by KEEPZ.ME to ensure compliance
with applicable laws.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.5<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>By
agreeing to this agreement, the user confirms and authorizes KEEPZ.ME that the
information about the user (including web page addresses (URL); information
about persons included in management bodies; user addresses; </span><span
style='color:black'>the amount of money and transactions traded within the
KEEPZ.ME service and any other information/documentation) to transfer to the
partner bank and/or international payment systems and/or supervisory, </span><span
style='color:black'>controlling and/or registration bodies, state or local
self-government bodies and legal entities created by them.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.6<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>3.6
KEEPZ.ME will make a decision based on the examination of the information
provided by the user to approve/refuse to use KEEPZ.ME's services, which will
be notified to the user by KEEPZ.ME in writing (through the e-mail indicated in
the registration and service application).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>DETERMINING THE LIMIT AND RESERVE VOLUMES</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.1</span></b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><b><span style='color:black'>LIMITATIONS</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In the case of successful completion of the procedure
specified in the above Article 3, KEEPZ.ME is authorized, based on a message
sent via e-mail, to set a monetary and/or quantitative limit for the
performed/to be performed card operation, For a payment operation and/or a
refund operation in a certain period of time (eg day, week, month, etc.). In
addition, KEEPZ.ME is entitled not to provide services for those card
operations, payment operations and/or refund operations that exceed the
established monetary and/or quantitative limit.</span><span style='color:black'>
</span><span style='color:black'>In addition, KEEPZ.ME is entitled, without
additional consent, based on 30 (thirty) calendar days prior notice sent via
e-mail, to change the said limits.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.2</span></b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><b><span style='color:black'>RESERVES</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In order to ensure the financial obligations stipulated in
this Agreement, KEEPZ.ME is entitled to set a fixed reserve amount for the
user.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.3dy6vkm"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>4.2.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME is authorized to deduct funds from the reserve
account in a non-acceptable manner and apply it to compensate for any damages,
fees and expenses incurred or incurred by the user of KEEPZ.ME as a result of
non-fulfillment of any obligations under this Agreement. </span><span
style='color:black'>In addition, the user is obliged to replenish the reserve
in the appropriate amount within 10 (ten) banking days from the corresponding
request of KEEPZ.ME.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In case of termination of the contract, the user will be
given the right to dispose of the funds in the reserve account after 180 (one
hundred and eighty) days have passed since the last authorized transaction
carried out by him.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>If there is a fixed reserve, the user will be able to use
the acquiring service or continue using the acquiring service, only after
presenting a payment order confirming the registration/replenishment of the
reserve.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>PAYMENT TERMS AND PROCEDURE</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.1<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>Each
card transaction/payment transaction may be subject to acceptance by the
relevant authorized financial institution as defined by law.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
and/or the relevant financial institution may withhold, reject or cancel the
card transaction/payment transaction according to the rules and laws of the
international or local payment systems and/or in accordance with the internal
management procedure.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
is entitled not to accept a payment operation/payment order for execution
and/or refuse to execute a payment operation/payment order if:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The payment operation/payment order is made or submitted in
violation of the rules established by KEEPZ.ME, contrary to these terms or
contains inaccurate information (instructions);</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The amount available on the user's payment account is not
sufficient to fulfill the payment task;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
has not received the payment order;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>the payment operation/payment order will exceed the limit
set by KEEPZ.ME;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>5.3.5 Funds on the payment account are subject to a public
legal restriction (for the avoidance of any doubt, KEEPZ.ME is entitled to
impose a public legal restriction on the user's account and the claim secured
by said restriction </span><span style='color:black'>(including the
satisfaction of the collection order and seizure, in case of using the
enforcement mechanisms provided for by the law);</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>fraud or illegal activity is suspected;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The execution of the payment operation/payment order will
result in KEEPZ.ME and/or its related persons being sanctioned or other legal
restrictions imposed on them in accordance with the legislation, the
legislation of other states and/or international law;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The payment operation/payment order does not comply with
the requirements established by the law and/or the rules and procedures
established by KEEPZ.ME, or there is another defect/circumstance that makes the
execution of the order impossible.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.4<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>No
later than the next banking day after the successful completion of the card operation
and receipt of the corresponding amount by the partner bank</span><span
style='color:black'>, </span><span style='color:black'>The partner bank is
obliged to ensure the implementation of the card operation through the
electronic-technical means, through the payment instrument (card) and the
crediting of the payment to the settlement account, unless the payment
operation is considered suspicious by the partner bank.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.5<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
is obliged, in accordance with Article 5.4 of these terms and conditions, on
the day of crediting the amount of the operation to the settlement account, and
if the day of crediting is a non-working day for KEEPZ.ME - no later than the
next business day, to reflect the credited amount on the user's payment
account.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.6<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>Initiation
of a payment operation by the user through the control panel, for the purposes
of these terms of service, is considered as the issuance of a payment order and
consent to the implementation of the payment operation.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.7<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
is authorized to deduct funds from the user's payment account without
acceptance, without further consent of the user, in the following cases:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'><span style='color:black'>a) to return the deposited and/or
doubtful amount(s) by mistake and/or violation of legal requirements;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>b) to settle any kind of debt or fulfill an
obligation to KEEPZ.ME;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'><span style='color:black'>c</span><span style='color:black'>) </span><span
style='color:black'>in other cases stipulated by the current legislation and/or
the contract, agreement and/or other document concluded between the user and
KEEPZ.ME;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.1t3h5sf"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>5.8<span
style='font:7.0pt "Times New Roman"'> </span></span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><span style='color:black'>The payment
operation is carried out both in local and foreign currency. In addition, it is
allowed to carry out a payment operation in foreign currency, except for the
case when all providers participating in the payment operation are banks
licensed by the National Bank of Georgia, </span><span style='color:black'>a
micro bank, a provider or microfinance organization registered by the National
Bank of Georgia, and the said represents one of the following payment
operations:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'>a) the payment operation for goods or services (including
commission);</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'>b) the payment operation for the purpose of remuneration,
except for business trips;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'>c) commission payment operation related to financial
instruments.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.9<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>The
user is authorized to issue a payment order for the implementation of payment
operations only in the currency and within the amount in which the
corresponding payment account is denominated.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>CHARGEBACK</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.1<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>Chargeback
refers to the procedure within which the card issuer or the card user, in
accordance with the rules of the international card scheme, appeals the
performed card operation and requests back the total or partial amount of the
card operation.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.2<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
is obliged to advise the user at the request of Charjbek, to protect his
interests in relations with international card schemes, </span><span
style='color:black'>keep the customer informed about the progress of the
chargeback request and find any/all reasonable assistance to satisfy the
chargeback request in favor of the customer.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.3<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>In
the event of a chargeback request, KEEPZ.ME is authorized to block the user
from the reserve amount specified in Article 6.2 of the present terms before
the chargeback process is completed, and in case the chargeback request is
satisfied in favor of the user, to deduct the corresponding amount without
acceptance.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.4<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>In
the event that the above-mentioned reserve is not sufficient to cover the
chargeback amount, KEEPZ.ME will be entitled to deduct the remaining part of
the relevant chargeback amount from the available balance on the user's payment
account and/or from subsequent successfully executed card operation(s).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.5<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>If
the amount of the chargeback exceeds the sum of the amount of the reserve
placed by the user and the amount of the available balance on the user's
payment account at a particular moment, The user is obliged to provide
compensation for the difference to KEEPZ.ME by crediting the balance of the
chargeback amount to the bank account.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.6<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>In
the event of a chargeback request (as well as in case of any suspicious and
illegal transaction), KEEPZ.ME is entitled to request all necessary information
and documentation from the user, and the user is obliged to hand over the
chargeback documents to KEEPZ.ME upon request.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.7<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>In
the event that during one calendar month the amount of chargebacks reaches 2%
of the amount of card transactions or the number of transactions and/or the
amount increases sharply, until the reason is determined </span><span
style='color:black'>(based on verification) KEEPZ.ME will be authorized to
temporarily suspend the provision of services specified in this Agreement to
the user.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.8<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>By
agreeing to this agreement, the user acknowledges and agrees that KEEPZ.ME is
not responsible and, accordingly, is not obliged to refund the amount of the
chargeback operation to the user. In addition, the customer is responsible for
any charges related to the chargeback (including, but not limited to, appeal
fees, amounts to be paid as a result of clarification of the circumstances of
operations in incident cycles by the international payment system, as well as
other losses and expenses incurred by KEEPZ.ME in connection with the conduct
of incident cycles and for chargebacks and other amounts charged to KEEPZ.ME by
the international payment system, fines and any other costs incurred by
KEEPZ.ME).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>REFUND OPERATION</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.1<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>The
refund operation refers to the full or partial return of the payment made by
the user to his client due to the return of the sold product and/or service.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.2<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
is entitled not to carry out a refund operation after 14 (fourteen) working
days from the completion of the transaction. The mentioned period can be
modified by taking into account the customer's business activity and delivery
policy, based on the mutual agreement of the parties.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.3<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
will carry out the refund operation only if the user has an available balance
of the appropriate amount on the payment account at a particular moment.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.4<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>In
the event of a refund operation, the fee paid to KEEPZ.ME is non-refundable,
and the customer is responsible for any costs associated with the refund
operation.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>USER’S OBLIGATIONS</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>The user is obliged to:</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-left:14.2pt;text-align:justify;
text-indent:-.5in'><span lang="GEO/KAT" style='color:black'>8.1.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>In order
to receive payments, with the involvement of KEEPZ.ME, ensure the integration
of the Internet portal with the authorization page.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:14.2pt;text-align:justify;
text-indent:-.5in'><span lang="GEO/KAT" style='color:black'>8.1.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>to carry out activities through the Internet portal under
legitimate ownership and to ensure the sale of the product/service
specified/announced on the Internet portal in compliance with the full
requirements of this Agreement and legislation. Also, to have and, if
necessary, to submit all the necessary documents, which will confirm that only
the product/service that has been announced to KEEPZ.ME is being sold on the
Internet portal.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>8.1.6 Any information provided to KEEPZ.ME under this
Agreement shall be accurate and up-to-date, and in case of changes and/or
additions, KEEPZ.ME shall be informed in advance, and if it is not possible,
after the change, at the first opportunity/immediately.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>to place on the Internet portal:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.6.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>Your (including each Trading Platform Participant's, if any)
identification and contact information. More specifically, name
(business)/name, surname, identification/personal number, legal and
correspondence (actual) address, telephone number, e-mail</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Internet portal usage rules;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>detailed
description and price of products/services;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Terms of delivery of products/services, including
information on the term of delivery of products/services and delivery fees;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>procedure for payment by the client;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Conditions and procedure for returning money to the client;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Privacy policy for customer and card transaction related
information</span><span style='color:black;background:white'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>statement regarding the client's obligation to comply with
the requirements of the legislation and payment systems;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In addition, the user is obliged to take all necessary
measures that will make it impossible to sell the product/service through the
Internet portal before signing the contract with the participants of the
trading platform </span><span style='color:black'>and upon KEEPZ.ME's request,
immediately prohibit the Trading Platform Participant(s) from trading through
the internet portal of its trading/service facility, In the event that the
relevant agreement concluded between the user and the participant(s) of the
said trading platform is terminated or suspended for any reason.</span><span
style='color:black;background:white'> </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>In order to avoid any dispute, within a period of at least 180
(one hundred and eighty) calendar days from the date of the transaction, if no
other period is specified by the regulation of the inter-bank payment system,
be able to provide the following details about the purchase operation performed
on its Internet portal:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>Customer's first and last name;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>Customer's personal number;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>Customer's address;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>Customer's telephone number;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>Order delivery place, date, time;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>documentation/observation related to the receipt and delivery of
the order, complete correspondence with the client regarding the order; </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>product/service description; </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.8.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>Client's IP address;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.8.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>To sell products/services on the Internet portal in
compliance with the conditions and requirements of payment systems periodically
determined by KEEPZ.ME, in case of receiving a corresponding request from
KEEPZ.ME, to ensure the implementation of additional security measures.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>through the Internet portal to sell only the
products/services corresponding to the business activity category agreed with
KEEPZ.ME.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>require KEEPZ.ME's prior written consent to make any
changes or additions to the Registered Commercial Activity.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.11<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>provide accurate, current and complete information during
registration and ensure its regular updating.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.12<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>To maintain confidentiality, secure access to data and not
to share its data with third parties.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.13<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>not to distribute pornographic materials, arrange gambling,
pharmacy (unless there is a prior written consent of KEEPZ.ME), trade in
weapons, narcotic and/or psychotropic substances, sale of tobacco products
and/or any other action/activity prohibited by law.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.14<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>immediately provide a refund to KEEPZ.ME if:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.14.1</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><span style='color:black'>the relevant
funds were credited to the user's bank account by mistake or incorrectly;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.14.2</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><span style='color:black'>The card
operation is performed in violation of the terms of use of the card, the
legislation and/or the requirements of payment systems, which includes card
operations by an unauthorized person, card operations performed with an expired
card and/or such card operations that do not express the user's will;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.14.3</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><span style='color:black'>It will be
revealed that the user violates the obligations stipulated in the contract.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.15<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>immediately notify KEEPZ.ME of any malfunction/defect in
any technical means used to perform this Agreement and seek all necessary
support from KEEPZ.ME to remedy the malfunction/defect.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.16<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>To ensure the observance of the legislation and the implementation
of measures periodically determined by KEEPZ.ME related to the rules of payment
systems.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.17<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>When delivering the products, identify the client and,
taking into account the specifics of the products/services, sign the act of
acceptance with him.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.18<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>not to ask the customer for information about card data and
not to collect, store and/or process card data, including without limitation
bank card number, CVV2/CVC2, and card validity period, otherwise ensure
compliance with PCI DSS and/or PA Full compliance with DSS and/or Pin security
requirements.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.19<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>within 2 (two) business days after receiving the relevant
request from KEEPZ.ME, to provide any requested documentation/information
regarding the card operation, which according to the rules and legislation of
international or local payment systems may become/become the subject of a
dispute, </span><span style='color:black'>or any documentation/information that
KEEPZ.ME is required to obtain by law.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.20<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Provide any kind of information/documentation related to
the client, payment service (including card operation), this agreement and/or
other related agreement to KEEPZ.ME, upon oral or written request.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.21<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In the process of carrying out the card operation, to
follow all the security measures that KEEPZ.ME has defined for the user.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.22<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In case of substantial changes to the website/internet
portal, which without limitation implies a change in the type of
product/service, mentioned in advance in writing, </span><span
style='color:black'>(including by e-mail) to agree with KEEPZ.ME, on the basis
of which the corresponding amendment of the relevant attachment and/or
registration and service application will be made.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.23<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>not to use the Service to make payments for products and/or
services that are illegal and/or online sale is prohibited and/or restricted in
accordance with the laws of Georgia and/or the terms contained in this
Agreement. The customer is fully, individually and solely responsible for
ensuring that the product and/or service he sells complies with the legislation
and internationally recognized standards.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.24<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>To provide KEEPZ.ME with uninterrupted access to all
Internet resources and/or other information resources that the user uses for
the sale of the product/service.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.25<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>not to carry out a transaction (not to accept payment) that
is not intended for the sale of products/services offered by him to clients.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.26<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>to monitor the use of the Internet portal by the client in
order to detect suspicious operations in time.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.27<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>not to participate in transactions / fictitious operations
(without providing service / services to the client) directly and / or
indirectly related to money laundering</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>PAYMENT FEE</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The fee for the acquiring service (including fees payable
to the partner bank and KEEPZ.ME) is determined by the following amount from
each successfully executed card transaction (&quot;standard fee&quot;)</span><span
style='color:black'>: </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is entitled to set a monthly fee of 5 GEL for using the QR code</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>When paying with Georgian cards, the amount of commission
is determined by:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>By <span
style='color:black'>2.5%</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>When paying with foreign cards, the amount of commission is
determined:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>By <span
style='color:black'>3.2%</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The user's individual service fee will be specified in the
KEEPZ.ME application.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Based on the mutual written agreement of the parties, it is
possible to set a special service fee different from the standard fee; A
different fee will be charged within the scope of business promotion, considering
the number of customer turnover and transactions. The agreement can be signed
by e-mail. in the form of communication or by signing an additional agreement</span><span
style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>In addition to the standard service fee, it is possible in
certain cases to impose an additional fee, the payment of which is the
responsibility of the customer. The additional commission will be imposed,
taking into account the requirements/restrictions related to the commissions of
the legislation, including the Law on &quot;Payment System and Payment
Services&quot; and only in the case when the aforementioned is due to objective
circumstances, Among them, depending on the cost structure related to the
transaction (for example, interbank transfer fees).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>9.5 KEEPZ.ME is entitled, at its own discretion, at any
time to set and/or change the standard fee for the user 30 (thirty) calendar
days in advance, based on the notification sent to the electronic mail
specified in the registration and service application. </span><span
style='color:black'>In the event that the user states in writing or by e-mail
that he does not agree to the fee change, KEEPZ.ME has the right to immediately
terminate the contract, which will be notified to the user in writing.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>SUSPENSION AND TERMINATION OF SERVICE</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME may immediately, by written notice, at its sole
discretion, suspend and/or terminate the provision of the Acquisition Service
if:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>User's electronic wallet account is inactive for 6 (six)
months.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME determines that continuing to provide services to
a particular user will contravene/violate existing laws/regulations of the
international payment system, </span><span style='color:black'>its internal
policies and/or any regulations binding on it and/or international or local payment
system requirements.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>Such request is made by any government body and/or
financial partner.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>The User violates the terms of this Agreement.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.2.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>In addition to the grounds specified in Article 10.1 of this
Agreement, the service may be fully or partially terminated:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.2.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>KEEPZ.ME at any time, upon 30 (thirty) calendar days prior
notification to the User.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.2.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>by written agreement of the parties;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.2.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>in other cases stipulated by the Agreement, other related
contracts and/or legislation.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>10.3 The user is entitled to terminate the acquisition
service at any time by sending an appropriate written notice to KEEPZ.ME.</span><span
style='color:black'> </span><span style='color:black'>In the event that the
User resumes use of KEEPZ.ME's Acquiring Services after termination, this
Agreement shall be effective again from the date of resumption of use of the
Service</span><span style='color:black'>.</span></span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:2.0in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>II.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
lang="GEO/KAT"><a name="_heading=h.tyjcwt"></a><b>     </b><b><span
style='font-family:"Sylfaen",serif;color:black'>MONEY TRANSFERS</span></b><b><span
style='font-family:"Sylfaen",serif'>     </span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>INTRODUCTION</b><a
name="_heading=h.aomij4vnm4uv"></a></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Based
on this document and in accordance with its terms, KEEPZ.ME undertakes to
provide the customer with remittance services, and the customer undertakes to
comply with the terms of this document and pay KEEPZ.ME service fees (<a
name="_heading=h.dkcnw5juaeoc"></a>&quot;Money transfer service fee&quot;)</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
amount of money transfer service fee will be paid by the user along with the
execution of the relevant money transfer transaction.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ELIGIBILITY</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>     Only a natural person
who owns a card instrument issued in his name by an issuer registered in the
territory of Georgia can use the money transfer service  and/or card and who
does not engage in business activities while using the remittance transaction
service.           </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>REPRESENTATIONS
AND WARRANTIES</b><a name="_heading=h.40xmstj84yhs"></a></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The user
represents and warants that:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>at the
time of declaration of consent to this document/at the time of execution of the
remittance transaction operation, is/will be capable (including in the manner
prescribed by the applicable legislation);</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>consent
to this document is expressed/the remittance transaction operation is performed
by him/her voluntarily, without the use of violence, threats, deception,
mistake and/or any other circumstances on the part of KEEPZ.ME or a third
party;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Expression
of consent to this document and fulfillment of its conditions, execution of
remittance transaction operation does not cause/cause violation of the contractual,
court (including arbitration court), legislation and/or other obligations for
which he is responsible for protection;<a name="_heading=h.ls6ltdysf42m"></a></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
remittance transaction is not related to the customer's business activity.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Performs
remittance transactions using a device and/or Internet connection that is
protected from unauthorized, unauthorized access by third parties.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
card/card identification data through which the remittance transaction is
completed is in his lawful use/possession and he is the cardholder.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The user
is fully responsible for:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>On the Correctness,
completeness and security of any information, including card identification
data, provided by him when performing/initiating a remittance transaction completeness
and security and any consequences caused by failure of these requirements,
including any damage(s) incurred to keepz.me and/or users;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>     When
making/initiating a remittance transaction, the data of the third party
recorded by him is owned by the indicated person, and the user has obtained the
consent of this person regarding the processing of his data by KEEPZ.ME
(including the transfer to KEEPZ.ME). In addition, the user is obliged to
compensate KEEPZ.ME for the damage (losses) caused to KEEPZ.ME due to the
failure to fulfill the obligation specified in this subsection in full and in
full, including, unambiguously, the payment imposed by any third party to
KEEPZ.ME.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>When
using the P2P transaction service, the card memorized/saved by him on the
authorization page is owned by him and he agrees that together with his mobile
phone number registered within the service (including in relation to it),  KEEPZ.ME
will process, including store, the identification data of the card specified by
him in the application.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>     KEEPZ.ME
is authorized, in compliance with the requirements of the legislation and in
accordance with the rules and procedures established in KEEPZ.ME, the
information (including personal data) transmitted by the user in connection
with the remittance transaction service. process in case of use of any other
products/services by the user in order to exercise the rights of KEEPZ.ME.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-indent:-21.3pt;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>SERVICE
FEE</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Service
Fee</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is authorized to set a monthly fee of 5 GEL for using the QR code;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>When
paying with Georgian cards, the amount of the commission is determined by: - <span
style='font-family:"Times New Roman",serif'>​​</span>1% - 2.5% in between</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.1.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>When
paying with foreign cards, the amount of commission is determined by 2% - 3.2%</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
service tariff is displayed to the user in the KEEPZ.ME application<a
name="_heading=h.a11od3xymrv"></a></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-indent:-21.3pt;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Terms
of service for Money Transfers</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>To
initiate a Money Transfer transaction, the user must use the KEEPZ.ME
application.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>In order
to initiate a remittance transaction and receive funds based on the remittance transaction,
the user must enter the card identification data in the KEEPZ.ME application.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
implementation of money transfers by the user will be carried out in accordance
with the conditions established by Kifz.mi, which may be changed from time to
time, including the limit of transactions and the manner of implementation.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The User
is authorized </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.4.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>To use
the remittance transaction service in the KEEPZ.ME application to
store/remember one or more cards/card identification data. In addition, the
storage of card/card identification data will be carried out in accordance with
the legislation and the procedures available in KEEPZ.ME;     </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.4.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>delete
the card/card identification data memorized/stored for using the service at any
time;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.4.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>within
the framework of a one-time remittance transaction, to have a maximum of 500
GEL, and a total of 3,000 GEL per month. These limits may change from time to
time.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is authorized to request confirmation of the operation in the appropriate
manner/form (including through a one-time code) each time for performing a
money transfer transaction and/or storing/remembering card identification data
in the KEEPZ.ME application.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
background:white'>5.6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>If the
transaction is completed, the recipient will receive a short text (SMS)
message.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
background:white'>5.7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Since
the storage/memorization of the card/card identification data is carried out in
relation to the particular mobile phone number participating/indicated in the
P2P transaction, the user is obliged to change the mobile phone
number/financial number <a name="_heading=h.z9rx1t8f6bw3"></a>and/or in case of
loss and/or transfer of ownership/use to a third party and/or termination of
its use for any reason, to immediately delete the card/card identification data
stored/remembered on the authorization page for using the remittance
transaction service. Furthermore, KEEPZ.ME is not responsible for any
consequences arising from the user's breach of this obligation.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ADDITIONAL
CONTRACTUAL TERMS OF MONEY TRANSFER SERVICE</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is authorized:<a name="_heading=h.fo4ur4bkipza"></a></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>By placing
relevant information in the KEEPZ.ME application and/or in any other form
acceptable to KEEPZ.ME, unless otherwise specified by law, without the
additional consent and acceptance of the user, to change any of the conditions
specified in this document (including the amount of the relevant commission,
limits and/or card schemes, in which it is possible to perform a remittance
transfer operation with the included cards). Furthermore, such change is
binding for users from the next banking day after its publication, unless
otherwise determined by KEEPZ.ME and/or the law.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>At any
time, unilaterally, without additional notice and/or consent/acceptance of the
user, to fully or partially terminate the remittance transaction service.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is not responsible for any restrictions and/or inability to use the remittance
transaction service due to restrictions/bans imposed by the card issuer. In
addition, KEEPZ.ME disclaims all liability if the user is charged any kind of
payment by the card issuer due to the use of the service.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>This
section regulates only the terms and conditions of use of the remittance
transaction and does not regulate the legal relationship between the user and
the card issuer, including the terms of use of the card. In addition, if the
user is a minor, the consent of the legal representative of the minor to the
terms of this document is considered to be given by the consent given to the
issuer of the card, by which the legal representative agreed to the use of the
card by the minor and the execution of operations through it.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>User and
KEEPZ.ME acknowledge and agree that if applicable to user, the united states
foreign assets control office, the united kingdom's financial sanctions
implementation office, Any sanctions imposed by the European Union and/or the
United Nations Security Council and/or any of their legal successors
(hereinafter referred to as the Sanctions Authority) and/or any restrictions
provided for and/or required by any sanctions and/or ban and/or if the User
and/or the country/territory of which the User is a resident or in which the
User is located falls on a list of specific sanctioned countries/territories
and/or persons issued/produced by the Sanctioning Authority;  Subject to
amendments thereto from time to time: (i) KEEPZ.ME is authorized to act in
accordance with the relevant sanction imposed by the Sanctioning Authority and
to take any and all actions determined and/or required by the relevant sanction
and/or sanctioned by the Sanctioning Authority; and (ii) KEEPZ.ME's action
under the relevant sanction imposed by the Sanctioning Authority and/or taking
any action determined by the Sanctioning Authority and/or provided for and/or
required by the relevant sanction shall not be deemed to constitute any breach
of any obligation owed by KEEPZ.ME to the User and KEEPZ.ME shall not be liable
in any way for action under the relevant sanction imposed by the Sanctioning
Authority and/or for carrying out any action determined by the sanctioning
authority and/or provided for and/or required by the relevant sanction.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is authorized not to perform a Money transfer operation if::</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>the
operation does not comply with the requirements established by law and/or the
rules and procedures established by KEEPZ.ME (including the conditions
established by the International Card Scheme);</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
operation contains inaccurate information;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>If
KEEPZ.ME has not received information about the operation; </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>the
amount necessary for the execution of the operation or its part is blocked
and/or for any other reason it is impossible to use it;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>the
operation will exceed the limit set by KEEPZ.ME;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
suspects the improper (including fraudulent) use of the application and/or card
by a third party;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>in
accordance with the information provided by the international payment scheme to
KEEPZ.ME, there is a risk of performing an unauthorized operation and/or there
is a demand of the international payment scheme for such action;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>the
operation cannot be performed for reasons independent of KEEPZ.ME;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>in other
cases provided for by law;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>There is
another error/circumstance that makes the operation impossible.</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:99.0pt;text-align:center;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='font-family:"Calibri",sans-serif'><span lang=EN-US style='font-family:
"Sylfaen",serif;color:black'>III. </span></span></b><b><span style='color:black'>OPEN
BANKING</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>INTRODUCTION</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>This
section defines the terms and conditions of KEEPZ.ME's provision of payment
initiation services and account information access services (&quot;<b>Open
Banking Services</b>&quot;) to users.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Open
banking services include:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>access
account information from multiple bank accounts (&quot;<b>Account Information
Access</b>&quot;); and</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Initiating
payments directly from the user's bank accounts through the KEEPZ.ME
application (&quot;<b>Payment Initiation</b>&quot;)     </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
services specified in article 1.2.1 and 1.2.2 allow the user to fully manage
and access his financial information, initiate transactions and view balances
in his bank accounts through the KEEPZ.ME application.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ELIGIBILITY</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>In order
for the user to receive open banking services, he must have:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>2.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>valid
bank accounts in financial institutions registered in Georgia; and </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>2.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Necessary
authorizations and permissions to manage bank accounts.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>REPRESENTATIONS
AND WARRANTIES </b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The user
declares and guarantees that:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Has full
authority to manage the bank accounts included in the KEEPZ.ME application.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>at the
time of declaration of consent to this document/at the time of inclusion of
bank accounts in the KEEPZ.ME application, is/will be capable (including in
accordance with the applicable legislation);</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Consent
to this document is expressed/open banking services are accepted by him/her
voluntarily, without using violence, threat, deception, mistake and/or any
other circumstances on the part of KEEPZ.ME or a third party;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Expressing
consent to this document and fulfilling its terms, receiving Open Banking
services does not cause/cause violation of the contractual, court (including
arbitration court), legislation and/or other obligations for which it is
responsible for protection;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Receives
Open Banking services using a device and/or Internet connection that is
protected from unauthorized, unauthorized access by third parties.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
card/card identification data through which the bank account is linked to Open
Banking is in his lawful use/possession and he is the cardholder.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The user
is fully responsible for:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>on the correctness,
completeness and security of any information, including card identification
data, provided by him/her when activating the bank account in Open
Banking/receiving Open Banking services and any consequences, including any
damage caused by failure to keepz.me and/or users;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>When
activating a bank account in Open Banking/receiving Open Banking services, the
data of the third party recorded by him (name, surname, mobile phone number) is
owned by the indicated person and the user has obtained the consent of this
person About the processing of his data by KEEPZ.ME (including transfer to
KEEPZ.ME). In addition, the user is obliged to compensate KEEPZ.ME for the
damage (losses) caused to KEEPZ.ME due to the failure to fulfill the obligation
specified in this subsection in full and in full, including, unambiguously, the
payment imposed by any third party to KEEPZ.ME.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is authorized, in accordance with the requirements of the legislation and in
accordance with the rules and procedures established in KEEPZ.ME, the
information (including personal data) provided by the user in connection with
the open banking service process in case of use of any other products/services
by the user in order to exercise the rights of KEEPZ.ME.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>OPEN
BANKING SERVICE TERMS</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>When
receiving open banking services, the user must use the KEEPZ.ME application.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.fdbhq5r5qnse"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>When
receiving open banking services, the user must use the KEEPZ.ME application.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.5tnycii0f9sy"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>to check
the balance of the bank account included in open banking; and</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.nl4lv1xq0eu3"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>have
access to information related to transactions carried out on a bank account
included in Open Banking;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.w2thbjq0q6ou"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>For the
purposes of Article 4.2, KEEPZ ME does not store sensitive payment data of the
User</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.sk1hgjeab7pw"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Within
the framework of payment initiation, the user is given the opportunity to:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.mi1j8hl6tkpu"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.4.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>through
the KEEPZ.ME application, through cards included in Open Banking, to initiate
payments to specified own or third party bank accounts;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is authorized to request confirmation of the operation in the appropriate
manner/form (including through a one-time code) each time a payment initiated
within the framework of open banking and/or saving/memorizing bank account
identification data in the KEEPZ.ME application.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is authorized:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>By
placing relevant information in the KEEPZ.ME application and/or in any other
form acceptable to KEEPZ.ME, unless otherwise specified by law, without
additional consent and acceptance of the user, change any of the conditions
defined in this document. However, such change is binding for users from the
next banking day after its publication, unless otherwise determined by KEEPZ.ME
and/or the law.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>At any
time, unilaterally, without additional notification and/or consent/acceptance
of the user, to fully or partially terminate the Open Banking service.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is not responsible for any restrictions and/or inability to use Open Banking
services due to restrictions/prohibitions imposed by the financial institution
servicing the bank account.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>This
document regulates only the terms and conditions of the Open Banking service
and does not regulate the legal relationship between the user and the financial
institution servicing the bank account, including the conditions for using the
card.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>user and
KEEPZ.ME acknowledge and agree that if applicable to user, the united states
foreign assets control office, the united kingdom's financial sanctions
implementation office,  Any sanctions imposed by the European Union and/or the
United Nations Security Council and/or any of their legal successors
(hereinafter referred to as the Sanctions Authority) and/or provided for by any
sanctions and/or any restrictions and/or prohibitions requested and/or if the
User and/or the country/territory of which the User is a resident or in which
the User is located falls on the list of specific sanctioned
countries/territories and/or persons; which is issued/produced by the
Sanctioning Authority, subject to the amendments made thereto from time to
time: (i) KEEPZ.ME is authorized to act in accordance with the appropriate
sanction imposed by the Sanctioning Authority and to take any and all actions
determined and/or provided for and/or required by the relevant sanction by the
Sanctioning Authority; and (ii) KEEPZ.ME's action under the relevant sanction
imposed by the Sanctioning Authority and/or taking any action determined by the
Sanctioning Authority and/or provided for and/or required by the relevant
sanction shall not be deemed to constitute any breach of any obligation owed by
KEEPZ.ME to the User and KEEPZ.ME shall not be liable in any way for action
under the relevant sanction imposed by the Sanctioning Authority and/or for
carrying out any action determined by the sanctioning authority and/or provided
for and/or required by the relevant sanction.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is entitled not to provide open banking services to the user if:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>It does
not comply with legal requirements and/or KEEPZ.ME rules and procedures;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
request for the provision of services contains inaccurate information;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>If
KEEPZ.ME has not received information about the request for service provision;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
amount necessary for providing the service or its part is blocked and/or it is
impossible to use it for any other reason;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
suspects the improper (including fraudulent) use of the application and/or bank
account by a third party;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>the
operation cannot be performed for reasons independent of KEEPZ.ME;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>in other
cases provided for by law;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>There is
another error/circumstance that makes the operation impossible.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>Service Fee</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.9.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
is authorized to set a monthly fee of 5 GEL for using the QR code;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.9.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>When
paying with Georgian cards, the amount of the commission is determined by: - <span
style='font-family:"Times New Roman",serif'>​​</span>1% - 2.5% in between</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.9.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>When
paying with foreign cards, the amount of commission is determined by 2% - 3.2%</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>The
service tariff is displayed to the user in the KEEPZ.ME application</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;line-height:115%;layout-grid-mode:char'><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'>&nbsp;</span></p>

</div>

</body>

</html>

`,
      }}
    />
  );
};

export default En;
