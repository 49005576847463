import PhoneIcon from "assets/icons/PhoneIcon";
import LocationIcon from "assets/icons/LocationIcon";
import EnvelopeIcon from "assets/icons/EnvelopeIcon";
import appleStoreIcon from "../../../assets/images/appStoreIcon.png";
import googleStoreIcon from "../../../assets/images/googlePlayStoreIcon.png";
import { ReactComponent as Hlogo } from "assets/images/logo.svg";
import { Link } from "react-router-dom";
import FacebookIcon from "assets/icons/FacebookIcon";
import InstagramIcon from "assets/icons/InstagramIcon";
import LinkedInIcon from "assets/icons/LinkedInIocn";
import { Trans } from "react-i18next";

const Footer = () => {
  return (
    <footer className=" w-[100%] sm:mt-[49px] md:mt-[89px]   py-[40px] bg-[#F8F9FF] font-display ">
      <div className=" flex justify-between sm:text-[14px] md:text-[16px] flex-wrap sm:px-[14px] md:px-[100px] m-auto max-w-[1440px] flex-col   sm2:flex-row   gap-10 ">
        <div className="sm2:m-auto md:m-0 ">
          <Hlogo
            className="cursor-pointer"
            onClick={() => window.scrollTo(0, 0)}
          />
          <div className="flex items-center gap-3 mt-[40px] text-left">
            <div className="w-[24px] h-[25px]">
              <LocationIcon />
            </div>
            <p className="text-[170738] ">
              <a
                target="_blank"
                href="https://www.google.com/maps/place/13+Mikheil+Tamarashvili+St,+T'bilisi+0162/@41.7216501,44.7436372,17z/data=!3m1!4b1!4m6!3m5!1s0x404473176aebeb7b:0xdeadb1ab566c5800!8m2!3d41.7216461!4d44.7462121!16s%2Fg%2F1hhh4gcg8?entry=ttu"
                rel="noreferrer"
              >
                <Trans i18nKey={"footer.location"} />
              </a>
            </p>
          </div>
          <div className="flex items-center  gap-3 mt-[24px]">
            <EnvelopeIcon />

            <a className="text-[170738] " href="mailto:info@keepz.me">
              info@keepz.me
            </a>
          </div>
          <div className="flex items-center gap-3 mt-[24px]">
            <PhoneIcon />

            <a href="tel:0322050402" className="text-[170738] ">
              +995 591 050 002
            </a>
          </div>
        </div>
        {/* <div className=" flex  gap-[35px]  sm2:m-auto  md:m-0  flex-col   sm2:flex-row"> */}
        <div>
          <p className="text-[#170738] text-[14px]  font-bold">
            <Trans i18nKey={"navigation.products"} />
          </p>
          <p className="text-[#170738] text-[14px] mt-[20px]">
            <a href="#1">
              <Trans i18nKey={"paymentTypes.terminalForMerchant.title"} />
            </a>
          </p>
          <p className="text-[#170738] text-[14px] mt-[8px]">
            <a href="#2">
              <Trans i18nKey={"paymentTypes.distancePayment.title"} />
            </a>
          </p>

          <p className="text-[#170738] text-[14px] mt-[8px]">
            <a href="#3">
              <Trans i18nKey={"paymentTypes.onlinePayments.title"} />
            </a>
          </p>
          <p className="text-[#170738] text-[14px] mt-[8px]">
            <a href="#4">
              {" "}
              <Trans i18nKey={"paymentTypes.QRcheck.titleB"} />
            </a>
          </p>
          <p className="text-[#170738] text-[14px] mt-[8px]">
            <a href="#5">
              {" "}
              <Trans i18nKey={"paymentTypes.qrForRestaurant.title"} />
            </a>
          </p>
        </div>
        <div>
          <p className="text-[#170738] text-[14px]  font-[700] ">
            <Trans i18nKey={"footer.menu"} />
          </p>
          <p className="text-[#170738] text-[14px]  font-[400] mt-[20px] ">
            <a href="#price">
              <Trans i18nKey={"navigation.price"} />
            </a>
          </p>
          <p className="text-[#170738] text-[14px] mt-[8px] font-[400]">
            <a href="#whyKeepz">
              <Trans i18nKey={"footer.whyKeepz"} />
            </a>
          </p>
          <p className="text-[#170738] text-[14px] mt-[8px] font-[400]">
            <a
              href="https://www.developers.keepz.me/"
              target="_blank"
              rel="noreferrer"
            >
              <Trans i18nKey={"footer.developers"} />
            </a>
          </p>
        </div>
        <div>
          <p className=" text-[#170738]  font-[700]">
            <Trans i18nKey={"footer.downloadApp"} />
          </p>
          <div className="flex flex-row md:flex-col gap-3 mt-3">
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.tipapp"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googleStoreIcon} alt="icon" />
              </a>
            </div>
            <div>
              <a
                href="https://apps.apple.com/us/app/tip-app-mobile/id1579469134"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appleStoreIcon} alt="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="max-w-[1240px] m-auto  h-[1px] bg-[#C1C7CD] mt-[28px] sm2:mt-[40px]" />

      <div className=" sm:items-center max-w-[1240px] m-auto  sm:justify-center  flex sm:flex-col md:flex-row md:justify-between mt-[20px] ">
        <div className="flex sm:gap-[20px] md:gap-[48px] sm:flex-col md:flex-row">
          <p className="text-center">&copy; Keepz.me - 2024</p>
          <div className="flex gap-[16px] justify-center text-center flex-wrap whitespace-nowrap">
            <Link
              to="/terms-and-conditions"
              className="text-[14px] cursor-pointer"
            >
              <Trans i18nKey={"termsAndCondition"} />
            </Link>
            <Link className="text-[14px] cursor-pointer" to="/privacy">
              <Trans i18nKey={"privacyPolicy"} />
            </Link>
            {/* <Link
              className="text-[14px] cursor-pointer"
              to="/contractual-provisions"
            >
              Contractual Provisions
            </Link> */}
          </div>
        </div>
        <div className=" flex gap-[16px] sm:mt-[20px] md:mt-0">
          {/* <YoutubeIcon /> */}
          <a
            href="https://www.facebook.com/profile.php?id=100092255837367"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>

          <a
            href="https://instagram.com/keepzgeorgia?igshid=MzRlODBiNWFlZA=="
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/keepzgeorgia/?originalSubdomain=ge"
          >
            <LinkedInIcon />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
