import React from "react";
import ApplePayIcon from "assets/icons/ApplePayIcon";
import GooglePayIcon from "assets/icons/GooglePayIcon";
import BinanceIcon from "assets/icons/BinanceIcon";
import CardIcon from "assets/icons/CardIcon";
import DropdownIcon from "assets/icons/DropdownIcon";
import { useTranslation } from "react-i18next";
import bog from "../../assets/images/bogDef.svg";
import liberty from "../../assets/images/libertyIcon.svg";
import credo from "../../assets/images/credoDef.svg";
import tbc from "../../assets/images/tbcIcon.svg";

export default function Select({ onClick, type, style }) {
  let content = null;

  const { t } = useTranslation();
  switch (type) {
    case "apple":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <ApplePayIcon />
          <p>Apple Pay</p>
        </div>
      );
      break;
    case "gpay":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <GooglePayIcon />
          <p>Google Pay</p>
        </div>
      );
      break;
    case "binance":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <BinanceIcon />
          <p>Binance Pay</p>
        </div>
      );
      break;
    case "ob_bog":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <img src={bog} alt="bog icon" className="w-[30.01px] h-[26.44px]" />
          <p>Bank of Georgia</p>
        </div>
      );
      break;
    case "ob_tbc":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <img src={tbc} alt="tbc icon" className="w-[30.01px] h-[26.44px]" />
          <p>TBC Bank</p>
        </div>
      );
      break;
    case "ob_credo":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <img
            src={credo}
            alt="credo icon"
            className="w-[30.01px] h-[26.44px]"
          />
          <p>Credo Bank</p>
        </div>
      );
      break;
    case "ob_liberty":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <img
            src={liberty}
            alt="liberty icon"
            className="w-[30.01px] h-[26.44px]"
          />

          <p>Liberty Bank</p>
        </div>
      );
      break;
    default:
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <CardIcon />
          <p>{t("description.payzePayWithCard")}</p>
        </div>
      );
      break;
  }
  return (
    <div
      className="border border-[eef0f3] px-[14px] w-full h-[40px] flex items-center justify-between"
      style={style}
      onClick={onClick}
    >
      <div className="[&_svg]:h-[32px] [&_svg]:w-[32px]">{content}</div>
      <DropdownIcon />
    </div>
  );
}
