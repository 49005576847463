import font from "../../assets/fonts/NotoSansGeorgian_Condensed-Regular.ttf";
// PDFDocument.js
import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Svg,
  Path,
  Font,
} from "@react-pdf/renderer";
import {
  getTransactionDetails,
  getTransactionDetailsOB,
  getTransactionDetailsTbc,
} from "api";

Font.register({ family: "NotosG", src: font });

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 60,
  },
  section: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    color: "#FF0000",
  },

  image: {
    width: 100, // Adjust the width of the image as needed
    height: 100, // Adjust the height of the image as needed
  },

  paragph: {
    color: "#374151",
    fontSize: 24,
    fontFamily: "Helvetica-Bold",
  },

  smallerScreenStyles: {
    text: {
      fontSize: 10,
      color: "#fff",
    },
  },
});

const PDFDocument = ({ transactionId = "", paymentProviderEnum, currency }) => {
  const [pdfData, setPdfData] = useState({});
  const {
    transactionAmount,
    recipientName,
    receiverPN,
    transactionTime,
    commissionAmount,
  } = pdfData;
  const {
    year,
    month,
    dayOfWeek,
    monthValue,
    dayOfMonth,
    hour,
    minute,
    second,
  } = transactionTime || {};

  const dataValue = `${dayOfMonth}/${monthValue}/${year}   ${hour}:${
    minute?.toString().length == 1 ? `0${minute}` : `${minute}`
  }`;

  console.log(transactionId);
  const paymentMethod = localStorage.getItem("paymentMethod");

  useEffect(() => {
    (async () => {
      try {
        let data;
        if (paymentProviderEnum == "TBC") {
          data = await getTransactionDetailsTbc(transactionId);
        } else if (paymentProviderEnum == "BOG") {
          data = await getTransactionDetails(transactionId);
        } else if (paymentProviderEnum == "OB") {
          data = await getTransactionDetailsOB(transactionId);
        }
        setPdfData(data);
      } catch {
        console.log("Faild to get data");
      }
    })();
  }, []);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.paragph}>Payment information</Text>
          <Svg
            width="123"
            height="29"
            viewBox="0 0 123 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Path
              d="M42.0028 16.858L39.7925 18.9971V20.7029C39.7925 21.9481 38.7629 22.9583 37.4939 22.9583C36.2249 22.9583 35.1953 21.9481 35.1953 20.7029V8.29706C35.1953 7.05191 36.2249 6.04166 37.4939 6.04166C38.7629 6.04166 39.7925 7.05191 39.7925 8.29706V13.6471L46.5652 7.31873C46.9301 6.97894 47.4158 6.78966 47.9202 6.78966C49.6656 6.78966 50.5488 8.8535 49.3263 10.0758L45.3938 13.9937L50.0747 19.8295C51.0927 21.0974 50.1723 22.9583 48.5268 22.9583H47.9573C47.3624 22.9583 46.7976 22.6938 46.4234 22.2377L42.0051 16.8557L42.0028 16.858Z"
              fill="#170738"
            />
            <Path
              d="M67.7676 16.5809H57.0898C57.3034 17.5511 57.8087 18.3172 58.6034 18.8792C59.4004 19.4411 60.3903 19.7233 61.5732 19.7233C62.3885 19.7233 63.112 19.6017 63.7436 19.3586C63.9595 19.276 64.1685 19.1774 64.373 19.0627C65.1056 18.6498 66.0243 18.7714 66.5939 19.3907L66.6628 19.4664C67.4277 20.2944 67.2485 21.6225 66.2816 22.2005C64.9839 22.9735 63.3761 23.3611 61.456 23.3611C59.6898 23.3611 58.1257 23.0171 56.766 22.3289C55.4063 21.6408 54.359 20.6866 53.6217 19.4641C52.8844 18.2415 52.5146 16.8561 52.5146 15.3056C52.5146 13.755 52.8798 12.3925 53.6079 11.1608C54.336 9.92907 55.3351 8.97029 56.6075 8.28218C57.8799 7.59406 59.3017 7.25 60.8749 7.25C62.4482 7.25 63.7965 7.5803 65.039 8.23859C66.2816 8.89689 67.2577 9.8442 67.9651 11.0736C68.6748 12.3054 69.0285 13.7344 69.0285 15.3629C69.0285 15.3675 69.0285 15.3721 69.0285 15.3812C69.0033 16.0556 68.4406 16.5855 67.7653 16.5855L67.7676 16.5809ZM58.314 11.5484C57.6158 12.131 57.1886 12.9247 57.0324 13.9339H64.7496C64.5934 12.9453 64.1662 12.1563 63.468 11.5645C62.7698 10.9727 61.9154 10.6768 60.9048 10.6768C59.8942 10.6768 59.0123 10.9681 58.3117 11.5484H58.314Z"
              fill="#170738"
            />
            <Path
              d="M85.9239 16.5809H75.5066C75.715 17.5511 76.2079 18.3172 76.9832 18.8792C77.7608 19.4411 78.7266 19.7233 79.8805 19.7233C80.676 19.7233 81.3819 19.6017 81.9981 19.3586C82.2087 19.276 82.4126 19.1774 82.612 19.0627C83.3268 18.6498 84.2232 18.7714 84.7789 19.3907L84.8461 19.4664C85.5923 20.2944 85.4175 21.6225 84.4741 22.2005C83.2081 22.9735 81.6396 23.3611 79.7663 23.3611C78.0431 23.3611 76.5172 23.0171 75.1906 22.3289C73.8641 21.6408 72.8423 20.6866 72.123 19.4641C71.4037 18.2415 71.043 16.8561 71.043 15.3056C71.043 13.755 71.3993 12.3925 72.1096 11.1608C72.8199 9.92907 73.7946 8.97029 75.036 8.28218C76.2774 7.59406 77.6644 7.25 79.1994 7.25C80.7343 7.25 82.0496 7.5803 83.2619 8.23859C84.4741 8.89689 85.4264 9.8442 86.1166 11.0736C86.809 12.3054 87.1541 13.7344 87.1541 15.3629C87.1541 15.3675 87.1541 15.3721 87.1541 15.3812C87.1294 16.0556 86.5804 16.5855 85.9217 16.5855L85.9239 16.5809ZM76.7009 11.5484C76.0197 12.131 75.6029 12.9247 75.4506 13.9339H82.9795C82.8272 12.9453 82.4104 12.1563 81.7292 11.5645C81.048 10.9727 80.2144 10.6768 79.2285 10.6768C78.2425 10.6768 77.3821 10.9681 76.6987 11.5484H76.7009Z"
              fill="#170738"
            />
            <Path
              d="M103.495 8.24639C104.679 8.91064 105.608 9.84333 106.282 11.0445C106.956 12.2456 107.293 13.6446 107.293 15.2416C107.293 16.8385 106.956 18.2375 106.282 19.4387C105.608 20.6398 104.679 21.5725 103.495 22.2367C102.312 22.901 100.998 23.2308 99.5548 23.2308C97.572 23.2308 96.0126 22.6053 94.8789 21.3564V26.3474C94.8789 27.5895 93.8704 28.5972 92.6274 28.5972C91.3845 28.5972 90.376 27.5895 90.376 26.3474V9.9912C90.376 8.60581 91.5006 7.48203 92.887 7.48203C93.875 7.48203 94.6763 8.28278 94.6763 9.27007C95.7918 7.92336 97.4195 7.25 99.5548 7.25C100.998 7.25 102.312 7.58213 103.495 8.24411V8.24639ZM101.62 18.3718C102.359 17.5938 102.731 16.5496 102.731 15.2416C102.731 13.9335 102.359 12.8916 101.62 12.1114C100.877 11.3334 99.9304 10.9421 98.7763 10.9421C97.6221 10.9421 96.6728 11.3311 95.9329 12.1114C95.1908 12.8894 94.822 13.9335 94.822 15.2416C94.822 16.5496 95.193 17.5915 95.9329 18.3718C96.6728 19.1498 97.6221 19.541 98.7763 19.541C99.9304 19.541 100.877 19.152 101.62 18.3718Z"
              fill="#170738"
            />
            <Path
              d="M122.999 21.2063C122.999 22.1733 122.21 22.9583 121.237 22.9583H110.666C109.694 22.9583 108.904 22.1733 108.904 21.2063V20.8541C108.904 20.4397 109.052 20.0391 109.321 19.7237L116.951 10.754H110.873C109.9 10.754 109.11 9.96897 109.11 9.00202C109.11 8.03507 109.9 7.25 110.873 7.25H120.973C121.946 7.25 122.735 8.03507 122.735 9.00202V9.35427C122.735 9.76867 122.587 10.1693 122.318 10.4847L114.691 19.4543H121.239C122.212 19.4543 123.002 20.2394 123.002 21.2063H122.999Z"
              fill="#170738"
            />
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.8478 20.1389C15.4049 20.1389 16.6672 21.3496 16.6672 22.8431V26.2958C16.6672 27.7893 15.4049 29 13.8478 29C12.2906 29 11.0283 27.7893 11.0283 26.2958V22.8431C11.0283 21.3496 12.2906 20.1389 13.8478 20.1389Z"
              fill="#170738"
            />
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.8478 0C15.4049 0 16.6672 1.21105 16.6672 2.70495V6.15616C16.6672 7.65006 15.4049 8.86111 13.8478 8.86111C12.2906 8.86111 11.0283 7.65006 11.0283 6.15616V2.70495C11.0283 1.21105 12.2906 0 13.8478 0Z"
              fill="#170738"
            />
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.6437 17.4096C19.3959 16.168 21.062 15.7425 22.3651 16.4592L25.3739 18.1141C26.6769 18.8308 27.1235 20.4183 26.3713 21.6599C25.6191 22.9014 23.953 23.3269 22.6499 22.6102L19.6411 20.9554C18.338 20.2387 17.8915 18.6512 18.6437 17.4096Z"
              fill="#9DD838"
            />
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M26.3708 7.33939C27.1236 8.58074 26.6775 10.1684 25.3743 10.8855L22.367 12.5403C21.0638 13.2575 19.3971 12.8325 18.6442 11.5911C17.8914 10.3498 18.3375 8.76215 19.6407 8.04504L22.648 6.39019C23.9512 5.67307 25.6179 6.09804 26.3708 7.33939Z"
              fill="#9DD838"
            />
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.32172 7.36454C2.06856 6.09961 3.72279 5.66612 5.01655 6.39631L15.3147 12.2085C16.1517 12.6809 16.6673 13.554 16.6673 14.4989V14.5011C16.6673 15.446 16.1517 16.3191 15.3147 16.7915L5.01655 22.6037C3.72279 23.3339 2.06856 22.9004 1.32172 21.6354C0.57489 20.3705 1.01826 18.7532 2.31202 18.023L8.5541 14.5L2.31202 10.977C1.01826 10.2468 0.57489 8.62947 1.32172 7.36454Z"
              fill="#6C63FF"
            />
          </Svg>
        </View>
        <View style={{ marginTop: "36px" }}>
          <Text
            style={{
              color: "#525F7F",
              fontSize: 18,
              fontFamily: "Helvetica-Bold",
            }}
          >
            Transaction details
          </Text>
          <View
            style={{
              border: "1px",
              borderColor: "#b6d1bd",
              borderRadius: "8px",
              padding: "10px",
              marginTop: 10,
              flexDirection: "row",
              gap: 100,
            }}
          >
            <View style={{ width: 160 }}>
              <Text
                style={{
                  marginTop: 8,
                  color: "#37415150",
                  fontSize: 14,
                }}
              >
                Transaction amount
              </Text>
              <Text
                style={{
                  marginTop: 8,
                  color: "#37415150",
                  fontSize: 14,
                }}
              >
                Net amount
              </Text>
              <Text
                style={{
                  marginTop: 8,
                  color: "#37415150",
                  fontSize: 14,
                }}
              >
                Transaction fee
              </Text>
              <Text
                style={{
                  marginTop: 8,
                  color: "#37415150",
                  fontSize: 14,
                }}
              >
                Transaction Data/time
              </Text>
              <Text
                style={{
                  marginTop: 8,
                  color: "#37415150",
                  fontSize: 14,
                }}
              >
                Transaction ID
              </Text>
            </View>
            <View>
              <Text
                style={{
                  marginTop: 8,
                  fontSize: 14,
                  color: "#374151",
                }}
              >
                {transactionAmount + currency}
              </Text>
              <Text
                style={{
                  marginTop: 8,
                  fontSize: 14,
                  color: "#374151",
                }}
              >
                {(transactionAmount - commissionAmount).toFixed(2) + currency}
              </Text>
              <Text
                style={{
                  marginTop: 8,
                  fontSize: 14,
                  color: "#374151",
                }}
              >
                {commissionAmount + currency}
              </Text>
              <Text
                style={{
                  marginTop: 8,
                  fontSize: 14,
                  color: "#374151",
                }}
              >
                {dataValue}
              </Text>
              <Text
                style={{
                  marginTop: 8,
                  fontSize: 14,
                  color: "#374151",
                }}
              >
                {transactionId}
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: "36px" }}>
          <Text
            style={{
              color: "#525F7F",
              fontSize: 18,
              fontFamily: "Helvetica-Bold",
            }}
          >
            Recipient
          </Text>
          <View
            style={{
              border: "1px",
              borderColor: "#b6d1bd",
              borderRadius: "8px",
              padding: "10px",
              marginTop: 10,
              flexDirection: "row",
              gap: 100,
            }}
          >
            <View style={{ width: 160 }}>
              <Text
                style={{
                  marginTop: 8,
                  color: "#37415150",
                  fontSize: 14,
                }}
              >
                Recipient name
              </Text>
              {receiverPN ? (
                <Text
                  style={{
                    marginTop: 8,
                    color: "#37415150",
                    fontSize: 14,
                  }}
                >
                  Recipient PN/ID
                </Text>
              ) : null}
            </View>
            <View>
              <Text
                style={{
                  marginTop: 8,
                  fontSize: 14,
                  color: "#374151",
                  fontFamily: "NotosG",
                }}
              >
                {recipientName}
              </Text>
              {receiverPN ? (
                <Text
                  style={{
                    marginTop: 8,
                    fontSize: 14,
                    color: "#374151",
                  }}
                >
                  {receiverPN}
                </Text>
              ) : null}
            </View>
          </View>
        </View>
        <Svg
          width="173"
          height="173"
          viewBox="0 0 173 173"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: 250, marginTop: 70 }}
        >
          <Path
            d="M52.0761 124.643C52.3225 124.904 52.4435 125.178 52.439 125.467C52.4274 125.753 52.2957 126.032 52.0154 126.297L45.8839 132.242C45.6125 132.501 45.3362 132.629 45.0459 132.632C44.7557 132.635 44.4837 132.505 44.2355 132.252L42.1262 130.079C40.9778 128.896 40.4245 127.651 40.468 126.336C40.5114 125.021 41.1496 123.766 42.3913 122.564C43.6349 121.355 44.925 120.744 46.2545 120.73C47.5911 120.718 48.8238 121.297 49.9668 122.471L52.0761 124.643ZM49.9227 125.142L48.7993 123.981C48.0867 123.244 47.321 122.898 46.511 122.936C45.6939 122.973 44.8684 123.404 44.0202 124.226C43.2025 125.017 42.7611 125.827 42.6994 126.642C42.6378 127.457 42.9597 128.232 43.6688 128.953L44.7921 130.114L49.9245 125.135L49.9227 125.142Z"
            fill="#170738"
          />
          <Path
            d="M39.5794 110.588C40.9402 110.872 42.0413 111.7 42.8791 113.085C43.7241 114.473 43.96 115.828 43.5832 117.165C43.2135 118.504 42.2838 119.618 40.8047 120.523C39.3184 121.427 37.8894 121.743 36.5358 121.462C35.175 121.178 34.0739 120.35 33.2289 118.963C32.3839 117.575 32.148 116.22 32.5248 114.883C32.9016 113.545 33.8332 112.425 35.3195 111.521C36.8058 110.617 38.2257 110.306 39.5794 110.588ZM41.2474 114.082C40.7857 113.317 40.1259 112.883 39.2752 112.782C38.4245 112.681 37.5081 112.929 36.5352 113.521C35.5533 114.118 34.9187 114.818 34.6152 115.626C34.3117 116.433 34.3953 117.216 34.857 117.981C35.3187 118.745 35.9856 119.181 36.8363 119.282C37.6871 119.383 38.6106 119.137 39.5853 118.538C40.5582 117.946 41.1928 117.246 41.4963 116.439C41.798 115.639 41.7091 114.847 41.2474 114.082Z"
            fill="#170738"
          />
          <Path
            d="M37.9671 102.453C38.4837 103.993 38.4132 105.355 37.7591 106.553C37.105 107.752 35.9377 108.63 34.2715 109.191C32.6072 109.744 31.1505 109.749 29.9052 109.19C28.6528 108.629 27.7781 107.599 27.2792 106.109C26.9525 105.142 26.8801 104.225 27.044 103.367C27.2169 102.504 27.6151 101.774 28.2405 101.17C28.4669 100.938 28.7147 100.772 28.9801 100.687C29.2635 100.591 29.5197 100.603 29.7486 100.722C29.9776 100.841 30.14 101.042 30.2341 101.332C30.3176 101.575 30.3294 101.799 30.2715 101.997C30.2207 102.198 30.0785 102.398 29.8665 102.603C29.4209 103.009 29.1382 103.463 29.004 103.962C28.8789 104.456 28.8934 104.971 29.0728 105.496C29.3462 106.312 29.8856 106.86 30.6928 107.133C31.5 107.406 32.4407 107.363 33.5312 106.999C34.6378 106.631 35.4296 106.093 35.9029 105.397C36.3763 104.702 36.479 103.935 36.2021 103.103C35.8239 101.979 35.0447 101.354 33.8697 101.24C33.5867 101.214 33.3683 101.143 33.2146 101.028C33.0609 100.913 32.9431 100.746 32.865 100.513C32.7603 100.204 32.7683 99.932 32.8837 99.6869C32.9991 99.4418 33.2021 99.2722 33.4855 99.1763C33.7097 99.1035 33.9677 99.0775 34.2669 99.0999C35.0998 99.1813 35.844 99.5222 36.5015 100.115C37.159 100.709 37.6529 101.497 37.9778 102.471L37.9671 102.453Z"
            fill="#170738"
          />
          <Path
            d="M35.6817 91.7996C35.8261 93.185 35.5735 94.3327 34.9186 95.2336C34.2708 96.1364 33.3393 96.6549 32.1349 96.7766L26.147 97.4013C25.7706 97.4432 25.4681 97.3742 25.2393 97.1943C25.0105 97.0144 24.8787 96.7524 24.8404 96.3922C24.8021 96.0319 24.8747 95.7455 25.0617 95.5185C25.2469 95.2987 25.5322 95.1652 25.9086 95.1234L31.6707 94.5177C32.3751 94.4446 32.9205 94.1865 33.2928 93.7397C33.6632 93.3001 33.812 92.7129 33.7353 91.9924C33.6587 91.2719 33.3988 90.7336 32.9394 90.381C32.4819 90.0213 31.9 89.8815 31.1939 89.9618L25.4317 90.5675C25.0554 90.6093 24.7528 90.5403 24.524 90.3604C24.2952 90.1805 24.1653 89.9114 24.1252 89.5583C24.085 89.2052 24.1594 88.9116 24.3446 88.6918C24.5317 88.4648 24.8151 88.3385 25.1915 88.2967L31.1794 87.672C32.3856 87.5431 33.4023 87.8616 34.2278 88.6041C35.0514 89.3538 35.5355 90.4213 35.6817 91.7996Z"
            fill="#170738"
          />
          <Path
            d="M35.0241 83.5021C35.1866 83.7032 35.2431 83.9614 35.1991 84.2856C35.1569 84.6026 35.0272 84.844 34.8225 84.9903C34.6159 85.1438 34.3434 85.1967 34.005 85.1491L25.405 83.9615C24.9483 83.8992 24.6049 83.7207 24.3818 83.428C24.1588 83.1353 24.0793 82.757 24.1416 82.3003C24.1966 81.9027 24.3265 81.6003 24.5404 81.3878C24.7543 81.1752 25.0844 81.015 25.5237 80.9053L32.5472 79.1407L32.5617 79.0834L26.2715 75.486C25.878 75.2644 25.6046 75.0199 25.4603 74.7471C25.3143 74.4815 25.2687 74.1499 25.3237 73.7523C25.386 73.2956 25.5644 72.9521 25.8572 72.7291C26.1499 72.506 26.5264 72.4337 26.9831 72.4961L35.583 73.6836C35.9215 73.7313 36.1648 73.8538 36.3272 74.055C36.4896 74.2562 36.5443 74.5215 36.5022 74.8385C36.46 75.1555 36.3302 75.397 36.1255 75.5433C35.919 75.6967 35.6483 75.7425 35.3098 75.6948L28.9181 74.8151L28.9036 74.8724L35.0899 78.4587C35.5227 78.7055 35.7061 79.0644 35.6438 79.5211C35.5779 79.9921 35.3046 80.2887 34.8241 80.4108L27.8973 82.1847L27.8828 82.242L34.2799 83.1307C34.6184 83.1783 34.8617 83.3009 35.0241 83.5021Z"
            fill="#170738"
          />
          <Path
            d="M37.6396 70.7982C37.5167 71.1329 37.3225 71.358 37.0498 71.4719C36.7843 71.5875 36.4744 71.5776 36.1182 71.4493L28.1097 68.4683C27.7607 68.3418 27.5212 68.1439 27.3984 67.8766C27.2738 67.6164 27.2729 67.319 27.3976 66.9772L29.2097 62.1094C29.3235 61.8106 29.489 61.6086 29.7097 61.4893C29.9305 61.3699 30.1831 61.3653 30.4551 61.4647C30.7271 61.5641 30.9111 61.7402 31.0054 61.9698C31.0978 62.2066 31.0916 62.4718 30.9797 62.7635L29.5686 66.5593L32.0347 67.4735L33.3771 63.8584C33.4765 63.5864 33.6312 63.397 33.8411 63.2901C34.0511 63.1833 34.2822 63.1732 34.5328 63.2672C34.7905 63.3629 34.9566 63.5193 35.0455 63.74C35.1273 63.9588 35.1265 64.2025 35.02 64.4727L33.6776 68.0877L36.2618 69.0471L37.6728 65.2513C37.7777 64.9578 37.9521 64.7505 38.1728 64.6311C38.4007 64.5136 38.6462 64.5072 38.9182 64.6065C39.1903 64.7059 39.3761 64.8749 39.4685 65.1117C39.561 65.3485 39.5548 65.6136 39.441 65.9125L37.6289 70.7803L37.6396 70.7982Z"
            fill="#170738"
          />
          <Path
            d="M41.4043 61.6239C41.4733 61.8929 41.4169 62.1758 41.2369 62.4655C41.0568 62.7552 40.8287 62.9337 40.5544 62.9937C40.2801 63.0538 39.9918 62.9884 39.6825 62.7958L32.0932 58.0692C31.7838 57.8765 31.6 57.6394 31.54 57.3651C31.4799 57.0908 31.5399 56.7936 31.7326 56.4842C31.8586 56.2875 32.0096 56.1429 32.1854 56.0502C32.3685 55.9594 32.6052 55.8974 32.9009 55.8733L41.0044 55.3501L41.0333 55.2964L35.8402 52.0607C35.5309 51.8681 35.3452 51.6381 35.269 51.3674C35.2 51.0984 35.2564 50.8155 35.4383 50.5186C35.6184 50.2289 35.8464 50.0505 36.1189 49.9976C36.3933 49.9375 36.6797 50.0101 36.9962 50.2045L44.6052 54.9437C44.9074 55.1346 45.093 55.3645 45.1477 55.6298C45.2096 55.897 45.1442 56.1852 44.9534 56.4874C44.8237 56.6984 44.6709 56.8503 44.4951 56.9429C44.3192 57.0355 44.0843 57.0903 43.7886 57.1145L35.6421 57.6268L35.6043 57.6858L40.8313 60.9378C41.1407 61.1304 41.3263 61.3603 41.3953 61.6293L41.4043 61.6239Z"
            fill="#170738"
          />
          <Path
            d="M49.8186 50.4269C49.8285 50.719 49.7129 50.9945 49.4649 51.2518C49.2169 51.509 48.9477 51.639 48.6574 51.6417C48.3672 51.6443 48.0881 51.5127 47.8165 51.261L42.0339 45.7499L40.4773 47.3827C40.2598 47.6095 40.0229 47.7324 39.7738 47.7532C39.5175 47.7721 39.2885 47.6836 39.0741 47.4769C38.8579 47.2773 38.7565 47.0459 38.7662 46.7969C38.776 46.5479 38.8914 46.3028 39.1017 46.0741L43.8022 41.1454C44.0197 40.9185 44.2494 40.7938 44.5057 40.7749C44.7548 40.7541 44.9892 40.8516 45.1982 41.0493C45.4126 41.256 45.5086 41.4785 45.5042 41.7365C45.4945 41.9855 45.3809 42.2235 45.1706 42.4521L43.605 44.0903L49.3876 49.6014C49.6573 49.8602 49.8016 50.133 49.8115 50.4251L49.8186 50.4269Z"
            fill="#170738"
          />
          <Path
            d="M58.0428 43.6171C57.9792 43.8982 57.7975 44.1341 57.4906 44.3231C57.1836 44.512 56.8878 44.5667 56.6085 44.4959C56.3293 44.4252 56.0898 44.2274 55.8955 43.9114L51.3586 36.4906C51.1643 36.1747 51.0971 35.8681 51.1607 35.587C51.2243 35.3059 51.406 35.07 51.7129 34.881L54.5276 33.1554C55.4915 32.5689 56.4453 32.3837 57.4032 32.6035C58.361 32.8232 59.126 33.4132 59.7143 34.37C60.2955 35.325 60.4573 36.2805 60.2053 37.2454C59.9533 38.2103 59.3274 38.9967 58.3365 39.5993L56.5953 40.6671L57.8521 42.7153C58.0464 43.0312 58.1135 43.3378 58.05 43.6189L58.0428 43.6171ZM55.6558 39.1413L56.9734 38.3319C57.5334 37.9861 57.8805 37.5786 58.0163 37.1025C58.1522 36.6263 58.0516 36.1207 57.729 35.5894C57.4063 35.0581 56.9988 34.7415 56.5189 34.6504C56.0337 34.5504 55.5137 34.6778 54.9554 35.0165L53.6307 35.824L55.6558 39.1413Z"
            fill="#170738"
          />
          <Path
            d="M66.7384 38.9838C66.6247 39.2522 66.3929 39.445 66.0557 39.5729C65.7185 39.7009 65.4229 39.6946 65.1581 39.5666C64.8933 39.4385 64.7024 39.1997 64.5691 38.8535L61.5466 30.6884C61.4152 30.3351 61.4089 30.0287 61.5298 29.7621C61.6435 29.4937 61.8752 29.3009 62.2125 29.1729L65.3407 28.0144C66.5102 27.5791 67.528 27.532 68.3942 27.8734C69.2603 28.2147 69.8817 28.8902 70.2566 29.9073C70.4965 30.5548 70.5554 31.1946 70.437 31.8124C70.3169 32.4373 70.0214 32.9721 69.5524 33.4096L71.9775 35.4184C72.2223 35.6252 72.3954 35.8443 72.4825 36.0721C72.5856 36.3573 72.5704 36.6278 72.4352 36.8908C72.3 37.1537 72.0773 37.3412 71.767 37.4531C71.514 37.5491 71.2882 37.5681 71.0967 37.5119C70.9052 37.4558 70.662 37.3028 70.3689 37.0457L67.517 34.5858L65.6928 35.2592L66.7252 38.0431C66.8566 38.3965 66.8629 38.7029 66.742 38.9695L66.7384 38.9838ZM65.1081 33.7166L66.9574 33.0343C67.4955 32.8353 67.862 32.5319 68.0549 32.1311C68.2479 31.7304 68.2493 31.2735 68.0592 30.7605C67.8726 30.2636 67.569 29.9276 67.1503 29.7454C66.7316 29.5631 66.2497 29.5706 65.7187 29.7714L63.8945 30.4448L65.1028 33.7076L65.1081 33.7166Z"
            fill="#170738"
          />
          <Path
            d="M76.6308 36.0025C76.4759 36.2529 76.2228 36.4098 75.875 36.4893C75.5218 36.5599 75.2281 36.516 74.9867 36.3558C74.7453 36.1956 74.5849 35.9263 74.509 35.5642L72.667 26.9208C72.5857 26.5497 72.6261 26.2399 72.7791 25.9967C72.9322 25.7535 73.1871 25.5894 73.5349 25.5099C73.8809 25.4375 74.1818 25.4832 74.4232 25.6434C74.6646 25.8036 74.825 26.0729 74.9063 26.444L76.7483 35.0873C76.8242 35.4495 76.791 35.7611 76.6379 36.0043L76.6308 36.0025Z"
            fill="#170738"
          />
          <Path
            d="M80.9861 35.2922C80.8027 35.5048 80.5461 35.6151 80.1985 35.6337C79.858 35.6542 79.5823 35.5691 79.3787 35.3804C79.175 35.1916 79.0612 34.9189 79.039 34.5552L78.5559 25.6311C78.5338 25.2673 78.6206 24.9845 78.8112 24.7737C79.0018 24.5629 79.2816 24.4509 79.6454 24.4287C79.8766 24.4187 80.0807 24.4551 80.2614 24.5542C80.442 24.6533 80.6243 24.8062 80.8171 25.0379L85.8948 31.3764L85.9557 31.3766L85.6288 25.266C85.6066 24.9022 85.6864 24.6176 85.8698 24.4049C86.0532 24.1923 86.3187 24.0766 86.6663 24.058C87.0068 24.0376 87.2825 24.1226 87.4861 24.3114C87.6898 24.5001 87.8018 24.78 87.8168 25.1419L88.3016 34.0893C88.3184 34.4441 88.2387 34.7287 88.0589 34.927C87.8791 35.1253 87.6065 35.2392 87.2427 35.2613C86.9954 35.2749 86.7841 35.2366 86.6088 35.1465C86.4335 35.0564 86.253 34.8964 86.0674 34.6665L80.9772 28.2867L80.9074 28.2919L81.2395 34.4419C81.2617 34.8057 81.182 35.0903 80.9986 35.303L80.9861 35.2922Z"
            fill="#170738"
          />
          <Path
            d="M94.9955 35.7618C94.7674 35.9403 94.4698 36.0021 94.1188 35.9437C93.766 35.8924 93.4995 35.7411 93.3372 35.5095C93.1695 35.2689 93.1149 34.9731 93.1716 34.5988L94.3731 26.6958L92.1417 26.3593C91.8337 26.3117 91.5976 26.191 91.4334 25.997C91.2691 25.803 91.209 25.5591 91.26 25.2672C91.3021 24.9807 91.4336 24.7625 91.6436 24.6252C91.8537 24.4879 92.1172 24.4403 92.4324 24.4897L99.1605 25.5156C99.4686 25.5631 99.7047 25.6839 99.8618 25.8761C100.019 26.0683 100.079 26.3121 100.035 26.6058C99.9912 26.8995 99.8615 27.1106 99.6515 27.2479C99.4414 27.3852 99.1779 27.4327 98.877 27.387L96.6385 27.0486L95.437 34.9516C95.3821 35.3187 95.2361 35.5942 95.008 35.7726L94.9955 35.7618Z"
            fill="#170738"
          />
          <Path
            d="M100.998 37.2747C100.662 37.159 100.428 36.9701 100.305 36.7028C100.18 36.4426 100.183 36.1309 100.304 35.7729L103.09 27.6921C103.209 27.3413 103.402 27.0928 103.664 26.9611C103.926 26.8293 104.225 26.8213 104.562 26.937L109.474 28.6309C109.775 28.7376 109.986 28.8977 110.105 29.1184C110.225 29.3392 110.238 29.5865 110.144 29.8675C110.052 30.1413 109.881 30.3343 109.652 30.4285C109.422 30.5228 109.155 30.5238 108.853 30.4243L105.019 29.1027L104.16 31.5905L107.804 32.8488C108.078 32.941 108.267 33.0957 108.383 33.3003C108.499 33.5049 108.509 33.736 108.422 33.9884C108.334 34.2479 108.183 34.423 107.969 34.5137C107.756 34.6045 107.512 34.6037 107.238 34.5115L103.594 33.2532L102.697 35.8609L106.531 37.1825C106.825 37.2873 107.036 37.4474 107.162 37.67C107.289 37.8925 107.302 38.1398 107.201 38.419C107.1 38.6982 106.938 38.8858 106.708 38.9801C106.479 39.0743 106.212 39.0753 105.909 38.9758L100.996 37.2818L100.998 37.2747Z"
            fill="#170738"
          />
          <Path
            d="M109.524 40.5619C109.211 40.3835 109.027 40.1465 108.962 39.8632C108.896 39.5799 108.958 39.2755 109.147 38.95L113.468 31.5781C113.657 31.2526 113.891 31.0526 114.169 30.9782C114.448 30.8967 114.748 30.9495 115.052 31.1332L117.666 32.6642C119.086 33.4964 119.954 34.5544 120.263 35.8292C120.573 37.104 120.291 38.4881 119.417 39.9815C118.544 41.4748 117.461 42.4121 116.186 42.7826C114.902 43.1585 113.558 42.925 112.137 42.1L109.522 40.569L109.524 40.5619ZM111.467 39.5072L112.861 40.3249C113.742 40.8454 114.571 40.9716 115.352 40.7196C116.125 40.4658 116.813 39.8247 117.405 38.8089C117.982 37.827 118.187 36.9264 118.032 36.1251C117.869 35.3219 117.35 34.6646 116.481 34.1548L115.088 33.3371L111.475 39.509L111.467 39.5072Z"
            fill="#170738"
          />
          <Path
            d="M123.674 51.4004C123.368 51.0716 123.194 50.7378 123.152 50.3919C123.11 50.0459 123.21 49.6521 123.428 49.212L127.292 41.389C127.396 41.1868 127.524 41.0134 127.684 40.8634C127.916 40.6402 128.185 40.5407 128.482 40.5703C128.781 40.5927 129.049 40.7368 129.296 40.9974C129.705 41.4287 129.755 41.9215 129.442 42.467L125.499 49.6986L125.535 49.7381L132.996 46.2172C133.283 46.0766 133.541 46.02 133.767 46.0619C133.992 46.1038 134.205 46.2263 134.41 46.4384C134.652 46.69 134.773 46.9645 134.782 47.2565C134.792 47.5486 134.682 47.8027 134.452 48.0188C134.303 48.1563 134.129 48.2722 133.924 48.3576L125.826 51.7779C125.381 51.9701 124.983 52.0369 124.639 51.9803C124.296 51.9237 123.972 51.7274 123.665 51.4058L123.674 51.4004Z"
            fill="#170738"
          />
          <Path
            d="M129.229 57.8676C128.944 57.8183 128.697 57.6492 128.491 57.3529C128.284 57.0566 128.211 56.7715 128.269 56.4815C128.327 56.1914 128.511 55.9483 128.816 55.736L136.081 50.7024C136.395 50.4848 136.691 50.3997 136.976 50.4489C137.26 50.4982 137.507 50.6674 137.714 50.9636C137.921 51.2599 137.994 51.545 137.935 51.8351C137.879 52.118 137.694 52.3682 137.379 52.5858L130.115 57.6195C129.81 57.8318 129.514 57.9169 129.222 57.8658L129.229 57.8676Z"
            fill="#170738"
          />
          <Path
            d="M130.788 60.7086C130.631 60.3946 130.593 60.0952 130.678 59.8196C130.763 59.5439 130.952 59.3403 131.241 59.1925C131.415 59.107 131.658 59.0468 131.958 59.0083L140.568 58.1104C141.037 58.0617 141.428 58.1149 141.739 58.2699C142.05 58.425 142.3 58.7017 142.485 59.0839C142.681 59.484 142.748 59.8515 142.684 60.1935C142.621 60.5355 142.428 60.8754 142.097 61.2183L136.112 67.4904C135.916 67.6923 135.718 67.8404 135.524 67.9437C135.246 68.079 134.967 68.0996 134.689 67.9913C134.412 67.8829 134.203 67.6852 134.057 67.3891C133.916 67.102 133.876 66.8403 133.936 66.604C133.995 66.3677 134.163 66.1281 134.44 65.8781L135.739 64.5993L133.999 61.0571L132.218 61.2917C131.843 61.3569 131.542 61.3416 131.322 61.2478C131.095 61.1521 130.92 60.9706 130.783 60.6996L130.788 60.7086ZM135.819 60.7331L137.099 63.3511L140.479 60.0846L140.452 60.0397L135.81 60.7385L135.819 60.7331Z"
            fill="#170738"
          />
          <Path
            d="M136.772 75.3544C136.531 75.1942 136.375 74.9339 136.312 74.5825C136.249 74.2312 136.302 73.9321 136.474 73.7015C136.649 73.4638 136.914 73.3177 137.282 73.2507L145.987 71.7063C146.362 71.6412 146.67 71.6887 146.911 71.8489C147.152 72.0091 147.308 72.2694 147.371 72.6208C147.434 72.9721 147.381 73.2712 147.209 73.5018C147.034 73.7395 146.769 73.8856 146.392 73.9579L142.665 74.6219L142.679 74.6864L147.819 77.9542C148.192 78.1936 148.415 78.4863 148.479 78.8377C148.533 79.1335 148.479 79.4093 148.313 79.6417C148.153 79.883 147.927 80.0239 147.631 80.0785C147.335 80.1331 147.029 80.048 146.702 79.8356L143.061 77.4199L139.713 81.3423C139.37 81.7354 139.047 81.9583 138.751 82.013C138.441 82.064 138.165 82.0094 137.918 81.8402C137.672 81.671 137.52 81.4269 137.469 81.1168C137.429 80.8855 137.436 80.6741 137.5 80.4844C137.563 80.2947 137.689 80.098 137.881 79.88L141.256 75.972L139.939 75.0972L137.68 75.5005C137.312 75.5675 137.004 75.5199 136.765 75.3525L136.772 75.3544Z"
            fill="#170738"
          />
          <Path
            d="M137.805 84.9663C137.811 84.6098 137.921 84.3252 138.132 84.127C138.342 83.9288 138.64 83.8365 139.019 83.8413L147.562 83.9931C147.934 83.996 148.226 84.108 148.433 84.3129C148.64 84.5178 148.736 84.8012 148.73 85.1577L148.64 90.3551C148.636 90.674 148.542 90.9245 148.373 91.1103C148.197 91.2943 147.969 91.3814 147.673 91.3751C147.378 91.3688 147.15 91.2732 146.986 91.0792C146.822 90.8852 146.74 90.6359 146.745 90.317L146.818 86.2662L144.194 86.2187L144.124 90.0724C144.12 90.3608 144.031 90.5899 143.873 90.7632C143.708 90.9347 143.496 91.0182 143.231 91.012C142.959 91.004 142.744 90.9191 142.59 90.7431C142.437 90.567 142.366 90.3357 142.37 90.0472L142.44 86.1936L139.686 86.1438L139.613 90.1946C139.61 90.5063 139.516 90.7569 139.347 90.9427C139.178 91.1285 138.941 91.2209 138.645 91.2147C138.35 91.2084 138.123 91.1127 137.958 90.9187C137.792 90.7318 137.713 90.4754 137.717 90.1565L137.807 84.9591L137.805 84.9663Z"
            fill="#170738"
          />
          <Path
            d="M137.248 94.1451C137.32 93.7977 137.477 93.5402 137.723 93.3815C137.969 93.2228 138.275 93.1861 138.646 93.2572L147.024 94.9296C147.388 94.9988 147.659 95.1591 147.82 95.3979C147.988 95.6385 148.035 95.9325 147.961 96.287L146.945 101.379C146.881 101.691 146.746 101.923 146.549 102.072C146.344 102.218 146.102 102.271 145.812 102.213C145.522 102.154 145.32 102.019 145.186 101.795C145.058 101.58 145.026 101.313 145.088 101.008L145.882 97.0331L143.307 96.5181L142.555 100.298C142.499 100.581 142.376 100.794 142.19 100.929C142.001 101.072 141.77 101.113 141.508 101.062C141.24 101.009 141.043 100.883 140.927 100.678C140.811 100.474 140.78 100.237 140.829 99.9522L141.581 96.1723L138.875 95.6318L138.082 99.6069C138.02 99.9113 137.885 100.144 137.685 100.299C137.486 100.454 137.239 100.498 136.949 100.44C136.659 100.382 136.456 100.247 136.321 100.03C136.193 99.8143 136.161 99.5472 136.218 99.2338L137.235 94.1343L137.248 94.1451Z"
            fill="#170738"
          />
          <Path
            d="M134.933 103.915C134.81 103.648 134.824 103.354 134.954 103.021C135.084 102.688 135.285 102.465 135.556 102.358C135.827 102.252 136.137 102.262 136.482 102.402L144.556 105.636C144.901 105.777 145.141 105.975 145.257 106.24C145.372 106.506 145.366 106.801 145.234 107.141L144.008 110.207C143.591 111.252 142.929 111.968 142.036 112.367C141.135 112.764 140.171 112.748 139.126 112.331C138.088 111.916 137.39 111.243 137.015 110.318C136.647 109.394 136.673 108.387 137.105 107.315L137.867 105.42L135.639 104.528C135.293 104.387 135.056 104.182 134.938 103.924L134.933 103.915ZM139.522 106.076L138.946 107.507C138.7 108.116 138.67 108.657 138.851 109.114C139.031 109.579 139.408 109.926 139.982 110.155C140.557 110.384 141.071 110.4 141.514 110.185C141.957 109.969 142.304 109.562 142.55 108.954L143.128 107.515L139.524 106.069L139.522 106.076Z"
            fill="#170738"
          />
          <Path
            d="M131.249 111.723C131.438 111.428 131.666 111.249 131.944 111.175C132.222 111.1 132.49 111.153 132.76 111.321C133.004 111.474 133.191 111.727 133.322 112.08L136.411 120.285L136.458 120.312L138.906 116.512C139.072 116.249 139.28 116.089 139.526 116.021C139.771 115.954 140.018 116.001 140.262 116.154C140.51 116.316 140.649 116.519 140.693 116.767C140.737 117.014 140.672 117.272 140.499 117.533L137.199 122.649C137.001 122.949 136.771 123.135 136.488 123.2C136.212 123.267 135.929 123.211 135.646 123.033C135.43 122.894 135.25 122.643 135.108 122.271L132.015 114.111L131.964 114.075L129.377 118.092C129.202 118.36 128.992 118.528 128.755 118.59C128.509 118.657 128.269 118.611 128.021 118.449C127.772 118.287 127.633 118.084 127.589 117.837C127.547 117.582 127.618 117.334 127.785 117.064L131.238 111.705L131.249 111.723Z"
            fill="#170738"
          />
          <Path
            d="M123.076 122.417C123.34 122.187 123.613 122.073 123.901 122.077C124.188 122.089 124.438 122.213 124.658 122.459C124.788 122.607 124.909 122.82 125.027 123.109L128.148 131.185C128.318 131.624 128.371 132.019 128.3 132.359C128.229 132.699 128.036 133.008 127.713 133.292C127.379 133.589 127.042 133.747 126.697 133.782C126.353 133.817 125.968 133.712 125.554 133.485L117.927 129.358C117.68 129.219 117.481 129.07 117.34 128.905C117.133 128.669 117.048 128.404 117.069 128.112C117.096 127.822 117.239 127.561 117.485 127.341C117.729 127.129 117.97 127.022 118.208 127.014C118.453 127.007 118.725 127.107 119.038 127.315L120.612 128.232L123.573 125.629L122.881 123.968C122.717 123.622 122.654 123.331 122.685 123.088C122.716 122.844 122.84 122.624 123.074 122.424L123.076 122.417ZM124.374 127.28L122.188 129.203L126.228 131.605L126.267 131.57L124.376 127.273L124.374 127.28Z"
            fill="#170738"
          />
          <Path
            d="M113.69 130.198C113.761 129.919 113.95 129.684 114.262 129.505C114.574 129.325 114.868 129.277 115.151 129.364C115.429 129.442 115.666 129.647 115.85 129.975L120.171 137.524C120.357 137.846 120.417 138.15 120.344 138.437C120.273 138.716 120.084 138.95 119.772 139.13L116.902 140.773C115.922 141.333 114.961 141.486 114.018 141.239C113.067 140.991 112.315 140.381 111.761 139.41C111.207 138.439 111.07 137.475 111.358 136.519C111.645 135.563 112.289 134.796 113.289 134.219L115.062 133.205L113.868 131.119C113.683 130.798 113.623 130.493 113.696 130.207L113.69 130.198ZM115.944 134.747L114.607 135.513C114.036 135.841 113.677 136.238 113.534 136.712C113.391 137.186 113.468 137.694 113.775 138.228C114.079 138.77 114.478 139.092 114.96 139.207C115.441 139.321 115.965 139.21 116.534 138.889L117.88 138.118L115.952 134.749L115.944 134.747Z"
            fill="#170738"
          />
          <Path
            d="M104.842 134.594C104.97 134.329 105.2 134.144 105.536 134.023C105.876 133.911 106.172 133.917 106.435 134.053C106.691 134.186 106.882 134.425 107.004 134.784L109.811 143.016C109.936 143.368 109.933 143.679 109.805 143.944C109.677 144.209 109.447 144.395 109.112 144.515L105.984 145.582C104.917 145.945 103.942 145.911 103.058 145.489C102.174 145.067 101.548 144.322 101.191 143.264C100.834 142.205 100.882 141.234 101.341 140.353C101.801 139.471 102.579 138.837 103.671 138.466L105.606 137.805L104.832 135.537C104.708 135.185 104.711 134.873 104.839 134.608L104.842 134.594ZM106.187 139.484L104.727 139.983C104.103 140.191 103.677 140.517 103.443 140.961C103.211 141.397 103.195 141.912 103.388 142.502C103.582 143.092 103.915 143.489 104.361 143.685C104.806 143.882 105.348 143.882 105.964 143.672L107.433 143.168L106.178 139.49L106.187 139.484Z"
            fill="#170738"
          />
          <Path
            d="M78.9317 116.395C76.1461 115.689 74.4617 112.862 75.1672 110.077L76.9955 102.858C77.7011 100.073 80.5282 98.3882 83.3139 99.0937C86.0995 99.7993 87.7839 102.626 87.0784 105.412L85.2501 112.63C84.5445 115.416 81.7174 117.101 78.9317 116.395Z"
            fill="#170738"
          />
          <Path
            d="M89.6943 73.901C86.9086 73.1954 85.2242 70.3683 85.9298 67.5827L87.7563 60.3714C88.4619 57.5858 91.2889 55.9014 94.0746 56.6069C96.8603 57.3125 98.5447 60.1396 97.8391 62.9253L96.0126 70.1365C95.307 72.9221 92.48 74.6065 89.6943 73.901Z"
            fill="#170738"
          />
          <Path
            d="M103.6 109.414C102.748 109.198 101.936 108.764 101.257 108.104L95.9246 102.92C93.8632 100.92 93.8214 97.6248 95.8201 95.5705C97.8206 93.5091 101.116 93.4673 103.17 95.466L108.502 100.65C110.563 102.65 110.605 105.945 108.607 107.999C107.266 109.382 105.34 109.854 103.6 109.414Z"
            fill="#170738"
          />
          <Path
            d="M102.744 86.5869C101.003 86.1462 99.5348 84.814 99.0137 82.9598C98.2357 80.1946 99.8392 77.324 102.597 76.5441L109.754 74.5237C112.519 73.7457 115.39 75.3492 116.17 78.1072C116.949 80.8652 115.344 83.7431 112.586 84.5229L105.429 86.5433C104.518 86.8002 103.598 86.7956 102.745 86.5798L102.744 86.5869Z"
            fill="#170738"
          />
          <Path
            d="M60.5592 98.52C58.8191 98.0792 57.3504 96.7471 56.8294 94.8929C56.0514 92.1277 57.6549 89.257 60.42 88.479L76.4265 83.9525L64.5047 72.3522C62.4433 70.3517 62.4015 67.0567 64.4002 65.0025C66.4007 62.9411 69.6957 62.8993 71.75 64.898L90.1293 82.7747C91.4619 84.0724 92.0007 85.9769 91.5454 87.7743C91.0901 89.5717 89.7079 90.9972 87.9183 91.5041L63.2451 98.4763C62.3341 98.7333 61.4132 98.7287 60.561 98.5128L60.5592 98.52Z"
            fill="#170738"
          />
          <Path
            d="M70.4812 149.758C69.851 149.599 69.4713 148.962 69.6309 148.331L70.0427 146.706C70.2023 146.076 70.8395 145.696 71.4697 145.856C72.0998 146.015 72.4795 146.652 72.3199 147.283L71.9082 148.908C71.7485 149.538 71.1113 149.918 70.4812 149.758Z"
            fill="#170738"
          />
          <Path
            d="M72.9079 140.177C72.2777 140.017 71.8981 139.38 72.0577 138.75L72.4694 137.124C72.629 136.494 73.2663 136.114 73.8964 136.274C74.5266 136.434 74.9063 137.071 74.7466 137.701L74.3349 139.327C74.1753 139.957 73.5381 140.337 72.9079 140.177Z"
            fill="#170738"
          />
          <Path
            d="M76.038 148.186C75.8447 148.137 75.664 148.038 75.5104 147.893L74.3064 146.719C73.842 146.266 73.8317 145.524 74.2847 145.06C74.7376 144.595 75.4794 144.585 75.9438 145.038L77.1478 146.212C77.6122 146.665 77.6225 147.407 77.1695 147.871C76.8694 148.184 76.4319 148.286 76.038 148.186Z"
            fill="#170738"
          />
          <Path
            d="M75.8493 143.033C75.4554 142.933 75.1267 142.637 75.0044 142.217C74.8271 141.593 75.1892 140.945 75.8133 140.768L77.4308 140.309C78.0548 140.132 78.7023 140.494 78.8796 141.118C79.0568 141.742 78.6947 142.39 78.0706 142.567L76.4531 143.026C76.2487 143.081 76.0426 143.082 75.8493 143.033Z"
            fill="#170738"
          />
          <Path
            d="M66.3351 145.729C65.9412 145.629 65.6125 145.332 65.4901 144.913C65.3129 144.289 65.675 143.641 66.2991 143.464L69.9107 142.443L67.2205 139.826C66.7561 139.373 66.7458 138.631 67.1987 138.167C67.6517 137.702 68.3935 137.692 68.8579 138.145L73.0039 142.175C73.304 142.464 73.4227 142.898 73.3212 143.299C73.2196 143.7 72.9086 144.025 72.5051 144.144L66.9407 145.714C66.7363 145.769 66.5302 145.771 66.3369 145.722L66.3351 145.729Z"
            fill="#170738"
          />
          <Path
            d="M75.3188 130.66C50.9711 124.493 36.1805 99.6684 42.3474 75.3207C48.5143 50.9729 73.3388 36.1823 97.6866 42.3492C122.034 48.5161 136.825 73.3407 130.658 97.6884C124.491 122.036 99.6665 136.827 75.3188 130.66ZM97.4489 43.2873C73.6168 37.251 49.3218 51.7261 43.2855 75.5583C37.2492 99.3904 51.7243 123.685 75.5564 129.722C99.3885 135.758 123.684 121.283 129.72 97.4508C135.756 73.6187 121.281 49.3236 97.4489 43.2873Z"
            fill="#170738"
          />
          <Path
            d="M69.1933 154.843C31.5045 145.297 8.61269 106.875 18.1569 69.1936C27.7011 31.512 66.1244 8.61301 103.813 18.159C141.502 27.705 164.394 66.1266 154.848 103.815C145.302 141.504 106.882 164.389 69.1933 154.843ZM103.565 19.1401C66.413 9.73012 28.5461 32.2975 19.138 69.4421C9.7298 106.587 32.2954 144.461 69.44 153.869C106.585 163.277 144.459 140.711 153.867 103.567C163.275 66.4223 140.709 28.5483 103.565 19.1401Z"
            fill="#170738"
          />
        </Svg>
        <Svg
          width="160"
          height="149"
          viewBox="0 0 160 149"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <Path
            opacity="0.05"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M182.666 -36.9057C173.789 -51.9472 154.128 -57.1019 138.751 -48.4191L16.3495 20.6949C6.40141 26.312 0.272995 36.6941 0.272995 47.9298V47.9565C0.272995 59.1922 6.40141 69.5743 16.3495 75.1915L138.751 144.305C154.128 152.988 173.789 147.834 182.666 132.792C191.543 117.751 186.273 98.5183 170.896 89.8355L96.7043 47.9432L170.896 6.05085C186.273 -2.63195 191.543 -21.8643 182.666 -36.9057Z"
            fill="#170738"
          />
        </Svg>
      </Page>
    </Document>
  );
};

export default PDFDocument;
