import { obStatusCheck } from "api";
import { useQueryString } from "hooks";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function RedirectPage() {
  const {
    parseQuery: {
      code,
      state,
      // receiverType = "",
      // receiverName = "",
      // ratingEnabled = "",
      // tipsEnabled = "",
      // acquiringTransactionId = "",
      // amount = "",
    },
  } = useQueryString();

  const navigate = useNavigate();
  const successParams = localStorage.getItem("successUrlParams");
  const {
    receiverType,
    receiverName,
    ratingEnabled,
    acquiringTransactionId,
    amount,
    tipsEnabled,
  } = successParams;

  //   const obState = localStorage.getItem("state");
  // const obId = localStorage.getItem("ob_id");
  // const codeVerifier = localStorage.getItem("codeVerifier");
  // console.log(obId, "obId");
  // console.log(codeVerifier, "codeVerifier");
  // console.log(code, "code");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       console.log("გაეშვა რექუესთი");
  //       const { status } = await obStatusCheck({
  //         id: obId,
  //         codeVerifier: codeVerifier,
  //         code: code,
  //       });
  //       console.log(status, "statusi simon");
  //       const url = `/success?acquiringTransactionId=${acquiringTransactionId}&amount=${amount}&receiverType=${receiverType}&receiverName=${receiverName}&ratingEnabled=${ratingEnabled}&tipsEnabled=${tipsEnabled}`;
  //       if (status == "SUCCESSFUL") {
  //         navigate(url);
  //       } else {
  //         console.log("erroria");
  //       }
  //     } catch (error) {
  //       console.log(error, "erroria amis dedac");
  //     }
  //   };

  //   fetchData();
  // }, []);

  window.open(
    `https://www.web.appdev.keepz.me/pending?code=${code}&state=${state}&receiverType=${receiverType}&receiverName=${encodeURIComponent(
      receiverName
    )}&ratingEnabled=${!!ratingEnabled}&tipsEnabled=${!!tipsEnabled}&acquiringTransactionId=${acquiringTransactionId}&amount=${amount}`,
    "_self"
  );

  return (
    <div className="spinWrapper">
      <span className="loader"></span>
    </div>
  );
}

export default RedirectPage;
